import { httpClient } from './../httpClient';
import { apiErrorHandler, constructFieldsToString } from './helpers';

export const getProperty = id => {
  return httpClient
    .get(`/property/${id}`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getProperties = ({ fields } = {}) => {
  return httpClient
    .get('/property', { params: { ...(!!fields && constructFieldsToString(fields)) } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getPropertiesWithAgodaSyncStatus = ({ fields } = {}) => {
  return httpClient
    .get('/property/with-agoda-sync-status', { params: { ...(!!fields && constructFieldsToString(fields)) } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getPropertiesByHostId = hostId =>
  httpClient
    .get('/property', { params: { host: hostId } })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateProperty = (id, payload) =>
  httpClient
    .put(`/property/${id}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const postCreateProperty = payload =>
  httpClient
    .post('/property', payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteRemovePropertyValidation = id =>
  httpClient
    .delete(`/property/${id}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteProperty = (id, payload) =>
  httpClient
    .patch(`/property/delete-reason/${id}`, { deletedReason: payload })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getPropertyList = () =>
  httpClient
    .get(`/property/list`)
    .then(response => response.data)
    .catch(apiErrorHandler);
