import React, { Component } from 'react';
import { Card, Col, Collapse, message, Row, Skeleton, Tooltip } from 'antd';
import queryString from 'query-string';
import moment from 'moment';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { withAppContext } from 'context/AppContext';

import { DATE_FORMAT, MONTH_FORMAT, REGEX_NOT_NUMBER_N_DOT } from 'utils/constants';
import { errorHandlerWrapper, flatArray, guard } from 'utils/general';
import { buildMultiCalendarUri } from 'utils/routes';
import { getConstants, getBookingStatusConstant, getBookingTypesConstant, getIntegrationSourcesConstant } from 'utils/apis/constants';
import { getProperty, getPropertiesByHostId } from 'utils/apis/property';
import { getRoomTypesByProperty, getUnitsByRoomType } from 'utils/apis/multiCalendar';
import { getClashingReservationDetails } from 'utils/apis/multiCalendar';
import { getHostsAutomated, checkHostIsAllowedAllOptions } from 'utils/apis/host';

import MCHeader from './components/MCHeader/MCHeader';
import MCCollapse from './components/MCCollapse/MCCollapse';
import MCDate from './components/MCDate/MCDate';
import MCRate from './components/MCRate/MCRate';
import MCModalRateUpdateWizard from './components/MCModalRateUpdateWizard/MCModalRateUpdateWizard';

import styles from './MultiCalendar.module.css';
import McModalBlockWizard from './components/MCModalBlockWizard/McModalBlockWizard';

const _no_room_type = '_no_room_type';
const DATE_RATEBLOCK_WIDTH = 100;
const RATE_HEIGHT = 25;

const OPTIONS_ALL_ID = 'all';

const MULTICALENDAR_ID = 'MultiCalendar page';
const MULTICALENDAR_BODY_ID = 'MultiCalendar body';
const MULTICALENDAR_BODY_LEFTCOL_ID = 'MultiCalendar left column body';
const MULTICALENDAR_BODY_RIGHTCOL_ID = 'MultiCalendar right column body';
const MULTICALENDAR_BODY_MONTH_HEADER_ID = 'MultiCalendar month header row';
const MULTICALENDAR_BODY_DATES_ID = 'MultiCalendar dates row';

const RESERVATIONS_COLORS = [
  { code: 'Freestay', bookingType: 4, color: styles.bookingTypeFreeStay, colorModal: styles.bookingTypeFreeStayModal, isNonProfitBookingType: true },
  {
    code: 'Maintenance',
    bookingType: 5,
    color: styles.bookingTypeMaintenanceAndBlock,
    isNonProfitBookingType: true,
    isBlockRservation: true
  },
  {
    code: 'Block',
    bookingType: 6,
    color: styles.bookingTypeMaintenanceAndBlock,
    isNonProfitBookingType: true,
    isBlockRservation: true
  },
  { code: 'Confirmed', bookingStatus: 'Confirmed', color: styles.bookingStatusConfirm, colorModal: styles.bookingStatusConfirmModal },
  {
    code: 'Awaiting Payment',
    bookingStatus: 'Awaiting Payment',
    color: styles.bookingStatusAwaitingPayment,
    colorModal: styles.bookingStatusAwaitingPaymentModal
  },
  { code: 'Check In', bookingStatus: 'checkin', color: styles.bookingStatusCheckIn },
  { code: 'Check Out', bookingStatus: 'checkout', color: styles.bookingStatusCheckOut },
  { code: 'Booking Inquiry', bookingStatus: 'Reserved', color: styles.bookingStatusReserved },
  { code: 'No Show', bookingStatus: 'No Show', color: styles.bookingStatusNoShow, colorModal: styles.bookingStatusNoShowModal },
  { code: 'Vacant Dirty (VD)', bookingStatus: 'vacantDirty', color: styles.bookingStatusVacantDirty },
  {
    code: 'Vacant Clean (VC)',
    bookingStatus: 'vacantClean',
    color: styles.bookingStatusVacantClean,
    colorModal: styles.bookingStatusVacantCleanModal
  },
  {
    code: 'Cancelled by Host',
    bookingStatus: 'Cancelled by Host',
    color: styles.bookingTypeCancellation,
    colorModal: styles.bookingTypeCancellationModal
  },
  {
    code: 'Cancelled by Guest',
    bookingStatus: 'Cancelled by Guest',
    color: styles.bookingTypeCancellation,
    colorModal: styles.bookingTypeCancellationModal
  },
  {
    code: 'Cancelled by OTA',
    bookingStatus: 'Cancelled by OTA',
    color: styles.bookingTypeCancellation,
    colorModal: styles.bookingTypeCancellationModal
  },
  { code: 'noBookingStatus', color: styles.noBookingStatus, hasNoBookingStatus: true }
];

class MultiCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      permissions: [],
      permissionConstants: {},

      clashingReservationDetails: [],
      hostOptions: [],
      propertyOptions: [],
      bookingTypeOptions: [],
      bookingStatusOptions: [],
      bookingStatusTypeOptions: [],
      integrationSourceOptions: [],
      filteredIntegrationSourceOptions: [],

      selectedHostId: '',
      selectedPropertyId: '',
      // selectedBookingTypeStatus: '',
      selectedBookingTypeStatus: [],
      selectedStartDateForRUW: '',
      selectedEndDateForRUW: '',
      selectedPropertyForRUW: undefined,
      selectedRoomTypeForRUW: undefined,
      selectedUnitForRUW: undefined,
      clickedRateDayRoomTypeUnitId: '',

      selectedRateFilters: [],
      propertiesAndRoomTypes: [],
      activePanelsRoomTypeId: [],

      todayDate: moment().format(DATE_FORMAT),
      month: moment().format(MONTH_FORMAT),
      monthStartDate: moment()
        .startOf('month')
        .format(DATE_FORMAT),
      monthEndDate: moment()
        .endOf('month')
        .format(DATE_FORMAT),

      isLoadingMultiCalendar: false,
      isLoadingMultiCalendarBody: false,
      isAnyRateDayStartDateClicked: false,
      isModalRateUpdateWizardShow: false,
      roomTypeIdsInLoading: [],

      isModalBlockWizardShow: false,
      selectedStartDateForBW: '',
      selectedEndDateForBW: '',
      currentExpandingRoomType: []
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoadingMultiCalendar: true });
    const { checkIsAdmin } = this.props;

    const isAdmin = checkIsAdmin();

    getConstants('permissions').then(res => {
      const authObj = JSON.parse(localStorage.getItem('auth'));

      if (res && res.status === 200) {
        this.setState({
          permissionConstants: res.data,
          permissions: authObj && authObj.user.roles.length > 0 ? authObj.user.roles[0].permissions : []
        });
      } else {
        console.log('Error getting permissions constants.');
      }
    });

    const bookingStatusOptions = await errorHandlerWrapper(
      getBookingStatusConstant().then(bookingStatus =>
        Object.keys(bookingStatus).map(key => ({
          value: bookingStatus[key].code,
          label: bookingStatus[key].label,
          isActive: bookingStatus[key].isActive,
          isUpdatable: !!bookingStatus[key].showDuringUpdate,
          isDefaultFilter: bookingStatus[key].isDefaultFilter,
          showInMCFilter: bookingStatus[key].showInMCFilter
        }))
      ),
      []
    );

    const bookingTypeOptions = await errorHandlerWrapper(
      getBookingTypesConstant().then(bookingTypes =>
        Object.keys(bookingTypes).map(key => ({
          value: bookingTypes[key].code,
          label: bookingTypes[key].label,
          isDefaultFilter: true,
          showInMCFilter: true
        }))
      ),
      []
    );

    const { integrationSourceOptions, filteredIntegrationSourceOptions, selectedRateFilters } = await errorHandlerWrapper(
      getIntegrationSourcesConstant().then(integrationSources => {
        const integrationSourceOptions = Object.keys(integrationSources).map(key => ({
          value: integrationSources[key].code,
          label: integrationSources[key].label,
          isIntegrated: integrationSources[key].isIntegrated
        }));
        const filteredIntegrationSourceOptions = integrationSourceOptions.filter(option => option.isIntegrated);
        const selectedRateFilters = filteredIntegrationSourceOptions.map(option => option.value);

        return { integrationSourceOptions, filteredIntegrationSourceOptions, selectedRateFilters };
      }),
      { integrationSourceOptions: [], filteredIntegrationSourceOptions: [], selectedRateFilters: [] }
    );

    const { queryHostId, queryPropertyId, queryRoomTypeId, queryDate } = this.constructDefaultQueries();

    const { date, month, monthStartDate, monthEndDate } = await this.constructDefaultMonthDetails(queryDate);

    const { hostOptions, selectedHostId } = await this.constructDefaultHostDetails(queryHostId, queryPropertyId);

    const { propertyOptions, selectedPropertyId } = await this.constructDefaultPropertyDetails(isAdmin, selectedHostId, queryPropertyId);

    this.setURLQuery({ hostId: selectedHostId, propertyId: selectedPropertyId, date: month });

    const { bookingStatusTypeOptions, selectedBookingTypeStatus } = await this.constructDefaultBookingStatusTypeOptions(
      bookingStatusOptions,
      bookingTypeOptions
    );

    const clashingReservationDetails = await this.constructClashingReservationDetails(isAdmin, selectedPropertyId);

    this.setState({
      isAdmin,
      clashingReservationDetails,
      hostOptions,
      propertyOptions,
      bookingStatusOptions,
      bookingTypeOptions,
      bookingStatusTypeOptions,
      integrationSourceOptions,
      filteredIntegrationSourceOptions,
      selectedHostId,
      selectedPropertyId,
      selectedBookingTypeStatus,
      selectedRateFilters,
      month,
      monthStartDate,
      monthEndDate,
      isLoadingMultiCalendar: false
    });

    await this.setPropertiesAndRoomTypes({
      currentPropertyId: selectedPropertyId,
      currentRoomTypeId: queryRoomTypeId,
      currentMonthStartDate: monthStartDate,
      currentMonthEndDate: monthEndDate,
      currentRateFilters: selectedRateFilters,
      currentClashingReservationDetails: clashingReservationDetails
    });
    this.scrollToDate(date);
    this.stickMonthDatesRow();
  };

  constructDefaultQueries = () => {
    const { location } = this.props;

    let queryHostId;
    let queryPropertyId;
    let queryRoomTypeId;
    let queryDate;

    if (!!location.search) {
      // Aki: hostId, propertyId and roomTypeId has renamed to without ...Id (became host, property, roomType)
      // so this is required to safeguard, in case old email cannot link to multi calendar properly
      // please remove the '|| Id' after few months of this commit
      queryHostId = queryString.parse(location.search).host || queryString.parse(location.search).hostId;
      queryPropertyId = queryString.parse(location.search).property || queryString.parse(location.search).propertyId;
      queryRoomTypeId = queryString.parse(location.search).roomType || queryString.parse(location.search).roomTypeId;

      // this has logic change, hence the email will have same problem as above, please remove the old logic after few months
      const queryMonth = queryString.parse(location.search).month;
      const queryYear = queryString.parse(location.search).year;
      queryDate = !!queryMonth && !!queryYear ? `${queryYear} ${queryMonth}` : queryString.parse(location.search).date;
    }

    return { queryHostId, queryPropertyId, queryRoomTypeId, queryDate };
  };

  constructDefaultMonthDetails = queryDate => {
    const { month: monthState, monthStartDate: monthStartDateState, monthEndDate: monthEndDateState } = this.state;

    const dateFromQuery = !!queryDate && moment(queryDate);
    const date = !!dateFromQuery && dateFromQuery.isValid() ? dateFromQuery : moment(monthState);

    const month = guard(() => date.format(MONTH_FORMAT), monthState);
    const monthStartDate = guard(
      () =>
        moment(date)
          .startOf('month')
          .format(DATE_FORMAT),
      monthStartDateState
    );
    const monthEndDate = guard(
      () =>
        moment(date)
          .endOf('month')
          .format(DATE_FORMAT),
      monthEndDateState
    );

    return { date, month, monthStartDate, monthEndDate };
  };

  constructDefaultHostDetails = async (queryHostId, queryPropertyId) => {
    const { getUserHosts } = this.props;

    const getPropertyHost = async () => {
      if (!!queryPropertyId) {
        return errorHandlerWrapper(getProperty(queryPropertyId).then(property => property.host));
      }
    };

    const hostOptions = await errorHandlerWrapper(getHostsAutomated().then(res => res.data.map(host => ({ label: host.name, value: host._id }))), []);
    const selectedHostId = queryHostId || (await getPropertyHost()) || getUserHosts()[0] || hostOptions[0].value;

    return { hostOptions, selectedHostId };
  };

  constructDefaultPropertyDetails = async (isAdmin, selectedHostId, queryPropertyId) => {
    const propertyOptions = await this.constructPropertyOptions(isAdmin, selectedHostId);
    const selectedPropertyId = queryPropertyId || (propertyOptions.length > 0 ? propertyOptions[0].value : undefined);

    return { propertyOptions, selectedPropertyId };
  };

  constructDefaultBookingStatusTypeOptions = (bookingStatusOptions, bookingTypeOptions) => {
    const bookingTypes = [/* 4, */ 5, 6];
    const filteredBookingTypeOptions = bookingTypeOptions.filter(bookingType => bookingTypes.includes(bookingType.value));
    let bookingStatusTypeOptions = bookingStatusOptions
      .filter(bookingStatusOption => bookingStatusOption.showInMCFilter === true)
      .concat(filteredBookingTypeOptions);

    bookingStatusTypeOptions.forEach(status => {
      if (RESERVATIONS_COLORS.findIndex(v => v.bookingType === status.value || v.bookingStatus === status.value) > -1) {
        status.color = RESERVATIONS_COLORS.find(v => v.bookingType === status.value || v.bookingStatus === status.value).color;
      }
    });
    // bookingStatusTypeOptions.splice(0, 0, { label: 'All', value: OPTIONS_ALL_ID });
    // const selectedBookingTypeStatus = bookingStatusTypeOptions && bookingStatusTypeOptions.length > 0 ? bookingStatusTypeOptions[0].value : '';
    const selectedBookingTypeStatus =
      bookingStatusTypeOptions && bookingStatusTypeOptions.length > 0
        ? bookingStatusTypeOptions.filter(bookingStatusType => bookingStatusType.isDefaultFilter).map(bookingStatusType => bookingStatusType.value)
        : [];

    return { bookingStatusTypeOptions, selectedBookingTypeStatus };
  };

  constructPropertyOptions = async (isAdmin, hostId) => {
    let propertyOptions = await errorHandlerWrapper(
      getPropertiesByHostId(hostId).then(properties =>
        properties.map(property => {
          return { label: property.name, value: property._id, currency: property.currency, timezone: property.timezone };
        })
      ),
      []
    );
    const showAllPropertyOptions = !isAdmin && (await checkHostIsAllowedAllOptions(hostId));
    if (showAllPropertyOptions) {
      propertyOptions.splice(0, 0, { label: 'All', value: OPTIONS_ALL_ID });
    }
    return propertyOptions;
  };

  constructClashingReservationDetails = (isAdmin, selectedPropertyId) => {
    let propertyId = '';

    if (isAdmin) {
      propertyId = selectedPropertyId;
    }

    return errorHandlerWrapper(getClashingReservationDetails(propertyId), []);
  };

  constructPropertiesAndRoomTypes = async ({ propertyId, monthStartDate, monthEndDate, rateFilters, clashingReservationDetails }) => {
    const { propertyOptions } = this.state;
    const month = new Date(monthStartDate).getMonth() + 1;
    const isSelectedAllProperty = propertyId === OPTIONS_ALL_ID;
    const properties = isSelectedAllProperty
      ? propertyOptions.slice(1, propertyOptions.length)
      : propertyOptions.filter(property => property.value === propertyId);

    const propertiesAndRoomTypes = await Promise.all(
      properties.map(async property => {
        const clashingReservationPropertyDetails =
          clashingReservationDetails && clashingReservationDetails.properties
            ? clashingReservationDetails.properties.find(propertyDetail => propertyDetail._id === property.value)
            : [];
        const clashingReservationMonthDetails =
          clashingReservationPropertyDetails && clashingReservationPropertyDetails.monthWithYears
            ? clashingReservationPropertyDetails.monthWithYears.find(monthDetail => monthDetail.month === month)
            : [];

        const roomTypes =
          (await this.constructRoomTypes({
            propertyId: property.value,
            monthStartDate,
            monthEndDate,
            rateFilters,
            clashingReservationMonthDetails
          })) || [];

        return {
          property: { _id: property.value, name: property.label, currency: property.currency, timezone: property.timezone },
          roomTypes,
          height: isSelectedAllProperty ? this.calculateHeight({ roomTypes }, []) : ''
        };
      })
    );

    return propertiesAndRoomTypes;
  };

  constructRoomTypes = ({ propertyId, monthStartDate, monthEndDate, rateFilters, clashingReservationMonthDetails }) => {
    return errorHandlerWrapper(
      getRoomTypesByProperty(propertyId, monthStartDate, monthEndDate, rateFilters).then(roomTypes => {
        if (roomTypes.length === 0) {
          message.error('There are no room types for the selected property.');
          return [
            {
              disabled: true,
              _id: _no_room_type,
              name: 'No Room Type, Please create a room type',
              clashedUnitIds: [],
              inventories: [],
              rates: {
                code: 'STD',
                name: 'Web Rate',
                weekday: '-',
                weekend: '-',
                _id: '88888888'
              }
            }
          ];
        }
        return roomTypes.map(roomType => {
          const clashingReservationRoomTypeDetails =
            clashingReservationMonthDetails && clashingReservationMonthDetails.roomTypes
              ? clashingReservationMonthDetails.roomTypes.find(roomTypeDetail => roomTypeDetail._id === roomType._id)
              : [];

          roomType.rates = this.filterRoomRates(roomType.rates);
          roomType.clashedUnitIds =
            clashingReservationRoomTypeDetails && clashingReservationRoomTypeDetails.clashedUnitIds
              ? clashingReservationRoomTypeDetails.clashedUnitIds
              : [];

          return roomType;
        });
      })
    );
  };

  constructUnits = async (selectedRoomTypeId, currentPropertiesAndRoomTypes) => {
    const { monthStartDate, monthEndDate, selectedRateFilters, propertiesAndRoomTypes } = this.state;
    currentPropertiesAndRoomTypes = currentPropertiesAndRoomTypes || propertiesAndRoomTypes;

    const currentPropertyAndRoomType = currentPropertiesAndRoomTypes.find(propertyAndRoomTypes =>
      propertyAndRoomTypes.roomTypes.map(roomType => String(roomType._id)).includes(selectedRoomTypeId)
    );

    if (currentPropertyAndRoomType) {
      const { roomTypes } = currentPropertyAndRoomType;
      const currentRoomType = roomTypes.find(roomType => String(roomType._id) === String(selectedRoomTypeId));

      if (currentRoomType && !currentRoomType.units) {
        return await getUnitsByRoomType(currentRoomType._id, monthStartDate, monthEndDate, selectedRateFilters, 1, 100).then(response => {
          // check here
          return response.data.map(res => {
            res.rates = this.filterRoomRates(res.rates);
            return res;
          });
        });
      }

      return currentRoomType && currentRoomType.units;
    }
  };

  setPropertiesAndRoomTypes = async ({
    currentPropertyId,
    currentRoomTypeId,
    currentMonthStartDate,
    currentMonthEndDate,
    currentRateFilters,
    currentClashingReservationDetails
  } = {}) => {
    this.setState({ isLoadingMultiCalendarBody: true });

    const { selectedPropertyId, monthStartDate, monthEndDate, selectedRateFilters, clashingReservationDetails } = this.state;

    currentPropertyId = currentPropertyId || selectedPropertyId;
    currentMonthStartDate = currentMonthStartDate || monthStartDate;
    currentMonthEndDate = currentMonthEndDate || monthEndDate;
    currentRateFilters = currentRateFilters || selectedRateFilters;
    currentClashingReservationDetails = currentClashingReservationDetails || clashingReservationDetails;

    const propertiesAndRoomTypes =
      (await this.constructPropertiesAndRoomTypes({
        propertyId: currentPropertyId,
        monthStartDate: currentMonthStartDate,
        monthEndDate: currentMonthEndDate,
        rateFilters: currentRateFilters,
        clashingReservationDetails: currentClashingReservationDetails
      })) || [];

    // if roomTypeId has passed in, which currently is only direct from other page, will expand the roomType and construct units in it
    let currentActivePanels = [];
    if (currentRoomTypeId) {
      const newActiveRoomTypeUnits = await this.constructUnits(currentRoomTypeId, propertiesAndRoomTypes);

      if (!newActiveRoomTypeUnits || newActiveRoomTypeUnits.length === 0) {
        message.error('There are no units for the selected room type.');
      } else {
        currentActivePanels.push(currentRoomTypeId);

        const currentPropertyAndRoomType = propertiesAndRoomTypes.find(propertyAndRoomType =>
          propertyAndRoomType.roomTypes.map(roomType => String(roomType._id)).includes(currentRoomTypeId)
        );
        const newlyActiveRoomType = currentPropertyAndRoomType.roomTypes.find(roomType => roomType._id === currentRoomTypeId);

        newlyActiveRoomType.units = newActiveRoomTypeUnits.map(newActiveRoomTypeUnit => {
          const hasClashed = !!newlyActiveRoomType.clashedUnitIds.find(clashedUnitId => String(newActiveRoomTypeUnit._id) === String(clashedUnitId));

          return { ...newActiveRoomTypeUnit, hasClashed };
        });
      }
    }

    this.setState({
      propertiesAndRoomTypes,
      activePanelsRoomTypeId: currentActivePanels,
      isLoadingMultiCalendarBody: false
    });
  };

  // Set the query so when the page refresh (example: creating reservation), the page will still goes to current selected month and selected property
  setURLQuery = ({ hostId, propertyId, date }) => {
    const { month, selectedHostId, selectedPropertyId } = this.state;

    if (!hostId) {
      hostId = selectedHostId || '';
    }
    if (!propertyId) {
      propertyId = selectedPropertyId || '';
    }
    if (!date) {
      date = month || '';
    }

    const monthWithYearInString = moment(date).format(MONTH_FORMAT);

    this.props.history.replace(buildMultiCalendarUri({ host: hostId, property: propertyId, date: monthWithYearInString }));
  };

  scrollToDate = date => {
    const { todayDate, month } = this.state;
    const multiCalendarBodyRightCol = document.getElementById(MULTICALENDAR_BODY_RIGHTCOL_ID);
    const selectedDate = moment(date || month);

    // Aki: _f is special case to access 'd' from moment object. A bit hacky.
    const shouldJumpToDate = guard(() => selectedDate._f.toLowerCase().includes('d'), false);
    const shouldJumpToToday = selectedDate.format(MONTH_FORMAT) === moment(todayDate).format(MONTH_FORMAT);

    if (!!multiCalendarBodyRightCol && (shouldJumpToDate || shouldJumpToToday)) {
      const dateToScroll = moment(shouldJumpToDate ? selectedDate : todayDate);
      const dayNumberToScroll = dateToScroll.format('D');
      const dayNumberMoveToLeft = 2 + 1; // 1 is so that we won't move beyond and cover the 'today'
      const scrollWidth = DATE_RATEBLOCK_WIDTH * (dayNumberToScroll - dayNumberMoveToLeft);

      multiCalendarBodyRightCol.scrollBy({ left: scrollWidth, top: 0, behavior: 'smooth' });
    }

    window.scrollBy({
      top: window.pageYOffset,
      behavior: 'smooth'
    });
  };

  stickMonthDatesRow = () => {
    const multiCalendar = document.getElementById(MULTICALENDAR_ID);
    const pages = multiCalendar ? multiCalendar.offsetParent.getElementsByClassName('layout ant-layout') : undefined;
    const page = pages && pages.length > 0 ? pages[0] : undefined;
    const multiCalendarBody = document.getElementById(MULTICALENDAR_BODY_ID);
    const multiCalendarBodyLeftCol = document.getElementById(MULTICALENDAR_BODY_LEFTCOL_ID);
    const multiCalendarBodyRightCol = document.getElementById(MULTICALENDAR_BODY_RIGHTCOL_ID);
    const monthHeader = document.getElementById(MULTICALENDAR_BODY_MONTH_HEADER_ID);
    const datesRow = document.getElementById(MULTICALENDAR_BODY_DATES_ID);

    const stickDatesRowOnWindowScroll = () => {
      const stickyHeight = !!monthHeader.style.height ? monthHeader.offsetHeight : 0;
      const offsetTop = multiCalendar.offsetTop + multiCalendarBody.offsetTop - stickyHeight;

      if (window.pageYOffset > offsetTop) {
        const pageScroll = page.scrollLeft;
        const monthHeaderWidth = multiCalendarBodyLeftCol.offsetWidth;
        const datesRowWidth = multiCalendarBodyRightCol.offsetWidth;
        const dateRowScroll = multiCalendarBodyRightCol.scrollLeft;

        monthHeader.classList.add(styles.monthRowSticky);
        monthHeader.classList.add(styles.monthRowStickyMonthHeader);
        monthHeader.style.width = `${monthHeaderWidth}px`;
        monthHeader.style.height = `${monthHeader.offsetHeight}px`;

        datesRow.classList.add(styles.monthRowSticky);
        datesRow.classList.add(styles.monthRowStickyDateRow);
        datesRow.style.width = `${datesRowWidth}px`;

        // move and hide the header
        monthHeader.style.marginLeft = `-${pageScroll}px`;
        monthHeader.style.clipPath = `inset(0 0 0 ${pageScroll - multiCalendarBody.offsetLeft - 2}px)`;
        datesRow.style.marginLeft = `-${pageScroll}px`;
        datesRow.style.clipPath = `inset(0 0 0 ${pageScroll - multiCalendarBody.offsetLeft - monthHeader.offsetWidth - 2}px)`;

        datesRow.scrollTo(dateRowScroll, 0);
      } else {
        monthHeader.classList.remove(styles.monthRowSticky);
        monthHeader.classList.remove(styles.monthRowStickyMonthHeader);
        monthHeader.style.width = '';
        monthHeader.style.marginLeft = '';
        monthHeader.style.clipPath = '';
        monthHeader.style.height = '';

        datesRow.classList.remove(styles.monthRowSticky);
        datesRow.classList.remove(styles.monthRowStickyDateRow);
        datesRow.style.width = '';
        datesRow.style.marginLeft = '';
        datesRow.style.clipPath = '';
      }
    };

    const stickDatesRowOnMCRatesScroll = () => {
      const offsetTop = multiCalendarBody.offsetTop;
      const dateRowScroll = multiCalendarBodyRightCol.scrollLeft;

      if (window.pageYOffset > offsetTop) {
        datesRow.scrollTo(dateRowScroll, 0);
      }
    };

    const handleDatesRowScroll = () => {
      const scrollPos = datesRow.scrollLeft;
      console.log(scrollPos, 12);
      multiCalendarBodyRightCol.scrollLeft = scrollPos;
    };

    if (multiCalendar && page && multiCalendarBody && multiCalendarBodyLeftCol && multiCalendarBodyRightCol && monthHeader && datesRow) {
      window.onscroll = () => stickDatesRowOnWindowScroll();
      page.onscroll = () => stickDatesRowOnWindowScroll();
      multiCalendarBodyRightCol.onscroll = () => stickDatesRowOnMCRatesScroll();
      datesRow.onscroll = () => handleDatesRowScroll();
    }
  };

  filterRoomRates = roomRates => {
    // Hide all rate except Web Rate
    if (!this.props.checkAbleViewRoomRates()) {
      return Object.keys(roomRates).reduce((newRates, key) => {
        const currentRates = roomRates[key];
        if (currentRates.length > 0 && key === 'web') {
          return {
            ...newRates,
            [key]: currentRates
          };
        }
        return newRates;
      }, {});
    }

    return Object.keys(roomRates).reduce((newRates, key) => {
      const currentRates = roomRates[key];
      if (currentRates.length > 0) {
        return {
          ...newRates,
          [key]: currentRates
        };
      }
      return newRates;
    }, {});
  };

  filterReservationsByBookingTypeStatus = (reservations, bookingTypeStatus) => {
    return reservations.filter(reservation => this.checkIsReservationFulfilledSelectedStatusFilter(reservation, bookingTypeStatus));
  };

  checkIsReservationFulfilledSelectedStatusFilter = (reservation, bookingTypeStatus) => {
    const { bookingStatus, bookingType } = reservation;
    const bookingTypesToBeIgnored = [4, 5, 6];
    if (bookingTypeStatus.includes(OPTIONS_ALL_ID)) {
      return true;
    } else if (bookingStatus && bookingType) {
      // if (bookingType === 4) {
      //   if (bookingTypeStatus.includes(bookingStatus) && bookingTypeStatus.includes(bookingType)) {
      //     return true;
      //   }
      // } else {
      if (bookingTypeStatus.includes(bookingStatus) /* && !bookingTypesToBeIgnored.includes(bookingType) */) {
        return true;
      }
      // }
    } else {
      if (bookingTypeStatus.includes(bookingType)) {
        return true;
      } else if (bookingTypeStatus.includes(bookingStatus) && !bookingTypesToBeIgnored.includes(bookingType)) {
        return true;
      }
    }
    // if (bookingTypeStatus.includes(OPTIONS_ALL_ID)) {
    //   return true;
    //   // } else if (bookingType === bookingTypeStatus) {
    // } else if (bookingTypeStatus.includes(bookingType)) {
    //   return true;
    //   // } else if (bookingStatus === bookingTypeStatus && !bookingTypesToBeIgnored.includes(bookingType)) {
    // } else if (bookingTypeStatus.includes(bookingStatus) && !bookingTypesToBeIgnored.includes(bookingType)) {
    //   return true;
    // }
    return false;
  };

  // i think the problem is that the property have too many roomTypes thus disrupting the spacing !, find a perfect balance ??
  // rateHeight = 25
  calculateHeight = (currentPropertyAndRoomType, activeRoomTypeIds) => {
    try {
      let totalHeight = currentPropertyAndRoomType.roomTypes.reduce((accumulativeHeight, currentRoomType) => {
        const roomTypeInitialHeight = RATE_HEIGHT * 2.975; // initially 3, 2.99
        const roomTypeHeight = this.calculateRatesHeight(roomTypeInitialHeight, currentRoomType.rates, 2);
        let unitTotalHeight = 0;
        activeRoomTypeIds.includes(currentRoomType._id);
        if (activeRoomTypeIds.includes(currentRoomType._id)) {
          unitTotalHeight = currentRoomType.units.reduce((accumulativeHeight, currentUnit) => {
            const unitInitialHeight = RATE_HEIGHT * 2;
            const unitHeight = this.calculateRatesHeight(unitInitialHeight, currentUnit.rates, 2);
            return accumulativeHeight + unitHeight;
          }, 0);
        }
        return accumulativeHeight + roomTypeHeight + unitTotalHeight;
      }, 0);

      totalHeight = Math.max(totalHeight - 3, 0); // -3 to the final height so can easily distinguish between different property

      return totalHeight;
    } catch (error) {
      return 0;
    }
  };

  calculateRatesHeight = (initialHeight, rates, rateNumberLimit) => {
    let finalHeight = initialHeight;
    const numberOfRates = Object.keys(rates).length;

    if (numberOfRates > rateNumberLimit) {
      const heightsToAdd = (numberOfRates - rateNumberLimit) * RATE_HEIGHT;
      finalHeight += heightsToAdd;
    }

    return finalHeight;
  };

  handleOnMonthChange = async date => {
    const month = date.format(MONTH_FORMAT);
    const monthStartDate = moment(month)
      .startOf('month')
      .format(DATE_FORMAT);
    const monthEndDate = moment(month)
      .endOf('month')
      .format(DATE_FORMAT);

    this.setState({
      month,
      monthStartDate,
      monthEndDate,
      activePanelsRoomTypeId: []
    });

    this.setURLQuery({ date: month });

    await this.setPropertiesAndRoomTypes({ currentMonthStartDate: monthStartDate, currentMonthEndDate: monthEndDate });
    this.scrollToDate(date);
  };

  handleOnHostFilterChange = async currentHostId => {
    const { isAdmin, selectedHostId } = this.state;

    if (String(currentHostId) !== String(selectedHostId)) {
      this.setState({ isLoadingMultiCalendar: true });

      const propertyOptions = await this.constructPropertyOptions(isAdmin, currentHostId);
      const firstPropertyId = propertyOptions.length > 0 ? propertyOptions[0].value : undefined;
      this.setURLQuery({ hostId: currentHostId });
      this.setState({ isLoadingMultiCalendar: false, isLoadingMultiCalendarBody: true, selectedHostId: currentHostId, propertyOptions });

      await this.handleOnPropertyFilterChange(firstPropertyId);
    }
  };

  handleOnPropertyFilterChange = async currentPropertyId => {
    const { isAdmin, clashingReservationDetails, selectedPropertyId } = this.state;
    if (String(currentPropertyId) !== String(selectedPropertyId)) {
      const currentClashingReservationDetails = isAdmin
        ? await this.constructClashingReservationDetails(isAdmin, currentPropertyId)
        : clashingReservationDetails;

      this.setState({
        clashingReservationDetails: currentClashingReservationDetails,
        activePanelsRoomTypeId: [],
        selectedPropertyId: currentPropertyId
      });

      this.setURLQuery({ propertyId: currentPropertyId });
      await this.setPropertiesAndRoomTypes({ currentPropertyId: currentPropertyId });
      this.scrollToDate();
    }
  };

  handleOnBookingStatusFilterChange = value => {
    this.setState({ selectedBookingTypeStatus: value });
  };

  handleOnClearButtonClick = async () => {
    const {
      isAdmin,
      selectedPropertyId,
      clashingReservationDetails,
      propertyOptions,
      bookingStatusTypeOptions,
      filteredIntegrationSourceOptions
    } = this.state;
    // const defaultPropertyId = propertyOptions.length > 0 ? propertyOptions[0].value : undefined;
    // const defaultBookingTypeStatus = bookingStatusTypeOptions.length > 0 ? bookingStatusTypeOptions[0].value : undefined;
    const defaultBookingTypeStatus =
      bookingStatusTypeOptions.length > 0
        ? bookingStatusTypeOptions.filter(bookingStatusType => bookingStatusType.isDefaultFilter).map(bookingStatusType => bookingStatusType.value)
        : [];
    const defaultRateFilters = filteredIntegrationSourceOptions.map(integrationSourceOption => integrationSourceOption.value);

    const currentClashingReservationDetails = isAdmin
      ? // ? await this.constructClashingReservationDetails(isAdmin, defaultPropertyId)
        await this.constructClashingReservationDetails(isAdmin, selectedPropertyId)
      : clashingReservationDetails;

    this.setState({
      clashingReservationDetails: currentClashingReservationDetails,
      // selectedPropertyId: defaultPropertyId,
      selectedBookingTypeStatus: defaultBookingTypeStatus,
      selectedRateFilters: defaultRateFilters
    });

    // this.setURLQuery({ propertyId: defaultPropertyId });
    // this.setPropertiesAndRoomTypes({ currentPropertyId: defaultPropertyId, currentRateFilters: defaultRateFilters });
  };

  handleOnRateCheckboxGroupChange = async checkedValues => {
    this.setState({
      selectedRateFilters: checkedValues,
      activePanelsRoomTypeId: []
    });

    this.setPropertiesAndRoomTypes({ currentRateFilters: checkedValues });
  };

  handleOnBulkRateUpdateSuccess = async () => {
    this.setState({
      isModalRateUpdateWizardShow: false
    });
    await this.setPropertiesAndRoomTypes();
    this.scrollToDate();
  };

  handleOnMCRateDateActionSuccess = async ({ currentRoomTypeId, selectedDate }) => {
    await this.setPropertiesAndRoomTypes({ currentRoomTypeId });
    this.scrollToDate(selectedDate);
  };

  handleOnCollapsePanelChange = async activeRoomTypeIds => {
    const { propertiesAndRoomTypes, activePanelsRoomTypeId } = this.state;
    let mutatedPropertyAndRoomTypes = [...propertiesAndRoomTypes];

    const newActiveRoomTypeIds = activeRoomTypeIds.filter(
      activeRoomTypeId => !activePanelsRoomTypeId.includes(activeRoomTypeId) && activeRoomTypeId !== _no_room_type
    );

    if (newActiveRoomTypeIds.length === 0 && activePanelsRoomTypeId.length === 0) {
      return message.warning(
        <span>
          No room type to show, please create new room type. <a href="/listing">Link</a> to create room type{' '}
        </span>
      );
    }
    this.setState({ roomTypeIdsInLoading: newActiveRoomTypeIds });

    await Promise.all(
      newActiveRoomTypeIds.map(async newActiveRoomTypeId => {
        const newActiveUnits = await this.constructUnits(newActiveRoomTypeId);

        if (!newActiveUnits || newActiveUnits.length === 0) {
          message.error('There are no units for the selected room type.');
        } else {
          mutatedPropertyAndRoomTypes = mutatedPropertyAndRoomTypes.reduce((result, propertyObject) => {
            const isSelectedProperty = propertyObject.roomTypes.map(roomType => String(roomType._id)).includes(newActiveRoomTypeId);

            if (isSelectedProperty) {
              propertyObject.roomTypes.reduce((resultRoomTypes, roomType) => {
                const isSelectedRoomType = roomType._id === newActiveRoomTypeId;

                if (isSelectedRoomType) {
                  roomType.units = newActiveUnits.map(unit => {
                    const hasClashed = !!roomType.clashedUnitIds.find(clashedUnitId => String(unit._id) === String(clashedUnitId));
                    return { ...unit, hasClashed };
                  });
                }

                return resultRoomTypes;
              }, propertyObject.roomTypes);
            }

            return result;
          }, mutatedPropertyAndRoomTypes);
        }
      })
    );

    mutatedPropertyAndRoomTypes = mutatedPropertyAndRoomTypes.map(mutatedPropertyAndRoomType => ({
      ...mutatedPropertyAndRoomType,
      height: this.calculateHeight(mutatedPropertyAndRoomType, activeRoomTypeIds)
    }));

    this.setState({
      activePanelsRoomTypeId: activeRoomTypeIds,
      propertiesAndRoomTypes: mutatedPropertyAndRoomTypes,
      roomTypeIdsInLoading: []
    });
  };

  handleOnExpandAllRoomTypesClick = () => {
    const { propertiesAndRoomTypes } = this.state;
    var allRoomTypeIds = flatArray(propertiesAndRoomTypes.map(propertyObject => propertyObject.roomTypes.map(roomType => roomType._id)));

    // Do not expand property with no room type
    allRoomTypeIds = allRoomTypeIds.filter(x => x !== '_no_room_type');

    this.handleOnCollapsePanelChange(allRoomTypeIds);
  };

  handleOnClickRateDay = (selectedRoomTypeUnitId, isStartDayClicked) => {
    this.setState({
      clickedRateDayRoomTypeUnitId: selectedRoomTypeUnitId,
      isAnyRateDayStartDateClicked: isStartDayClicked
    });
  };

  handleOnShowRateUpdateWizardModal = (propertyId, roomTypeId, unitId) => (startDate = '', endDate = '') => {
    this.setState({
      isModalRateUpdateWizardShow: true,
      selectedStartDateForRUW: startDate,
      selectedEndDateForRUW: endDate,
      selectedPropertyForRUW: propertyId,
      selectedRoomTypeForRUW: roomTypeId,
      selectedUnitForRUW: unitId
    });
  };

  handleOnCancelRateUpdateWizardModal = () => {
    this.setState({
      isModalRateUpdateWizardShow: false,
      selectedStartDateForRUW: '',
      selectedEndDateForRUW: '',
      selectedPropertyForRUW: undefined,
      selectedRoomTypeForRUW: undefined,
      selectedUnitForRUW: undefined
    });
  };

  handleOnShowBlockWizardModal = (startDate = '', endDate = '') => {
    this.setState({ isModalBlockWizardShow: true, selectedStartDateForBW: startDate, selectedEndDateForBW: endDate });
  };

  handleOnCancelBlockWizardModal = () => {
    this.setState({
      isModalBlockWizardShow: false,
      selectedStartDateForBW: '',
      selectedEndDateForBW: ''
    });
  };

  handleOnMCBlockDateActionSuccess = async ({ currentRoomTypeId, selectedDate }) => {
    await this.setPropertiesAndRoomTypes({ currentRoomTypeId });
    this.scrollToDate(selectedDate);
  };

  generateMCRates = (propertyId, roomTypes) => {
    const {
      clickedRateDayRoomTypeUnitId,
      isAnyRateDayStartDateClicked,
      month,
      integrationSourceOptions,
      bookingTypeOptions,
      bookingStatusOptions,
      activePanelsRoomTypeId,
      selectedBookingTypeStatus,
      permissions,
      permissionConstants,
      isAdmin
    } = this.state;

    const mcRates = [];
    for (let idx = 0; idx < roomTypes.length; idx++) {
      const roomType = roomTypes[idx];

      mcRates.push(
        <MCRate
          key={roomType._id}
          selectedPropertyId={propertyId}
          roomTypeId={roomType._id}
          unitId={''}
          unitName={''}
          isRoomType={true}
          inventories={roomType.inventories}
          rates={roomType.rates}
          calendarRates={roomType.calendarRates}
          unitReservations={[]}
          month={month}
          integrationSourceOptions={integrationSourceOptions}
          bookingTypeOptions={bookingTypeOptions}
          bookingStatusOptions={bookingStatusOptions}
          handleOnClickRateDay={this.handleOnClickRateDay}
          clickedRateDayRoomTypeUnitId={clickedRateDayRoomTypeUnitId}
          isAnyRateDayStartDateClicked={isAnyRateDayStartDateClicked}
          ratePerDayWidth={DATE_RATEBLOCK_WIDTH}
          reservationsColorsConstants={RESERVATIONS_COLORS}
          handleOnMCRateDateActionSuccess={this.handleOnMCRateDateActionSuccess}
          onRateUpdateWizardButtonClick={this.handleOnShowRateUpdateWizardModal(propertyId, roomType._id)}
          permissions={permissions}
          permissionConstants={permissionConstants}
          isAdmin={isAdmin}
          currency={roomType.currency}
          timezone={roomType.timezone}
        />
      );

      if (activePanelsRoomTypeId.includes(String(roomType._id))) {
        roomType.units &&
          roomType.units.forEach(unit => {
            const unitReservations = this.filterReservationsByBookingTypeStatus(unit.reservations, selectedBookingTypeStatus);
            mcRates.push(
              <MCRate
                key={unit._id}
                selectedPropertyId={propertyId}
                roomTypeId={roomType._id}
                unitId={unit._id}
                unitName={unit.name}
                isRoomType={false}
                rates={unit.rates}
                calendarRates={unit.calendarRates}
                unitReservations={unitReservations}
                month={month}
                integrationSourceOptions={integrationSourceOptions}
                bookingTypeOptions={bookingTypeOptions}
                bookingStatusOptions={bookingStatusOptions}
                handleOnClickRateDay={this.handleOnClickRateDay}
                clickedRateDayRoomTypeUnitId={clickedRateDayRoomTypeUnitId}
                isAnyRateDayStartDateClicked={isAnyRateDayStartDateClicked}
                ratePerDayWidth={DATE_RATEBLOCK_WIDTH}
                reservationsColorsConstants={RESERVATIONS_COLORS}
                handleOnMCRateDateActionSuccess={this.handleOnMCRateDateActionSuccess}
                onRateUpdateWizardButtonClick={this.handleOnShowRateUpdateWizardModal(propertyId, roomType._id, unit._id)}
                permissions={permissions}
                permissionConstants={permissionConstants}
                isAdmin={isAdmin}
                currency={unit.currency}
                timezone={unit.timezone}
              />
            );
          });
      }
    }

    return mcRates;
  };

  generateMCCollapse = ({
    activePanelsRoomTypeId,
    selectedPropertyId,
    propertiesAndRoomTypes,
    roomTypeIdsInLoading,
    clashingReservationIconStyle
  }) => {
    const RoomTypeCollapse = ({ propertyAndRoomTypes, isSelectedAllProperty }) => (
      <Collapse
        className={`${styles.mcCollapse} ${isSelectedAllProperty ? styles.mcCollapsePropertyAll : ''}`}
        onChange={this.handleOnCollapsePanelChange}
        activeKey={activePanelsRoomTypeId}
        disabled={true}
      >
        {propertyAndRoomTypes.roomTypes.map(roomType => (
          <MCCollapse
            key={roomType._id}
            roomType={roomType}
            activePanels={activePanelsRoomTypeId}
            isLoading={roomTypeIdsInLoading.includes(roomType._id)}
            calculateRatesHeight={this.calculateRatesHeight}
            clashingReservationIconStyle={clashingReservationIconStyle}
          />
        ))}
      </Collapse>
    );

    return propertiesAndRoomTypes.map(propertyAndRoomTypes => {
      const isSelectedAllProperty = selectedPropertyId === OPTIONS_ALL_ID;

      if (isSelectedAllProperty) {
        return (
          <div key={propertyAndRoomTypes.property._id} className={`${styles.mcCollapsePropertyAllContainer}`}>
            <Col className={styles.verticalPropertyText} style={{ height: propertyAndRoomTypes.height }} span={2}>
              <Tooltip title={propertyAndRoomTypes.property.name} placement="left" mouseEnterDelay={1}>
                {propertyAndRoomTypes.property.name}
              </Tooltip>
            </Col>
            <Col span={22}>
              <RoomTypeCollapse
                key={propertyAndRoomTypes.property._id}
                propertyAndRoomTypes={propertyAndRoomTypes}
                isSelectedAllProperty={isSelectedAllProperty}
              />
            </Col>
          </div>
        );
      } else {
        return (
          <RoomTypeCollapse
            key={propertyAndRoomTypes.property._id}
            propertyAndRoomTypes={propertyAndRoomTypes}
            isSelectedAllProperty={isSelectedAllProperty}
          />
        );
      }
    });
  };

  render() {
    const {
      isAdmin,
      clashingReservationDetails,
      hostOptions,
      propertyOptions,
      bookingStatusTypeOptions,
      filteredIntegrationSourceOptions,
      selectedHostId,
      selectedPropertyId,
      selectedBookingTypeStatus,
      selectedRateFilters,
      selectedStartDateForRUW,
      selectedEndDateForRUW,
      selectedPropertyForRUW,
      selectedRoomTypeForRUW,
      selectedUnitForRUW,
      month,
      todayDate,
      activePanelsRoomTypeId,
      isLoadingMultiCalendar,
      isLoadingMultiCalendarBody,
      isModalRateUpdateWizardShow,
      isModalBlockWizardShow,
      roomTypeIdsInLoading,
      propertiesAndRoomTypes
    } = this.state;

    const monthString = moment(month, MONTH_FORMAT).format('MMM');
    const yearString = moment(month, MONTH_FORMAT).format('YYYY');

    const clashingReservationIconStyle = styles.warningOnClashingReservationIcon;
    this.stickMonthDatesRow();

    return (
      <Card id={MULTICALENDAR_ID} className={`${styles.card}`} loading={isLoadingMultiCalendar}>
        <Row>
          <MCHeader
            isAdmin={isAdmin}
            month={moment(month)}
            clashingReservationDetails={clashingReservationDetails}
            bookingStatusTypeOptions={bookingStatusTypeOptions}
            hostOptions={hostOptions}
            propertyOptions={propertyOptions}
            filteredIntegrationSourceOptions={filteredIntegrationSourceOptions}
            selectedHostId={selectedHostId}
            selectedPropertyId={selectedPropertyId}
            selectedBookingTypeStatus={selectedBookingTypeStatus}
            selectedRateFilters={selectedRateFilters}
            onMonthChange={this.handleOnMonthChange}
            onHostFilterChange={this.handleOnHostFilterChange}
            onPropertyFilterChange={this.handleOnPropertyFilterChange}
            onBookingStatusFilterChange={this.handleOnBookingStatusFilterChange}
            onRateCheckboxGroupChange={this.handleOnRateCheckboxGroupChange}
            onClearButtonClick={this.handleOnClearButtonClick}
            onRateUpdateWizardButtonClick={this.handleOnShowRateUpdateWizardModal()}
            onExpandAllRoomTypesClick={this.handleOnExpandAllRoomTypesClick}
            reservationsColorsConstants={RESERVATIONS_COLORS}
            clashingReservationIconStyle={clashingReservationIconStyle}
            isRUWButtonDisabled={selectedPropertyId === OPTIONS_ALL_ID}
            onBlockWizardButtonClick={this.handleOnShowBlockWizardModal}
          />
        </Row>

        <Skeleton loading={isLoadingMultiCalendarBody} active={true} paragraph={{ rows: 5 }}>
          <Row id={MULTICALENDAR_BODY_ID}>
            <Col id={MULTICALENDAR_BODY_LEFTCOL_ID} span={6} className={`${styles.leftCol}`}>
              <Row id={MULTICALENDAR_BODY_MONTH_HEADER_ID} className={`${styles.monthRow} ${styles.monthHeader}`}>
                {monthString} {yearString}
              </Row>
              <Row>
                {this.generateMCCollapse({
                  activePanelsRoomTypeId,
                  selectedPropertyId,
                  propertiesAndRoomTypes,
                  roomTypeIdsInLoading,
                  clashingReservationIconStyle
                })}
              </Row>
            </Col>

            <Col id={MULTICALENDAR_BODY_RIGHTCOL_ID} span={18} className={styles.rightCol}>
              <Row id={MULTICALENDAR_BODY_DATES_ID} className={`${styles.monthRow} ${styles.monthDateRow}`}>
                <MCDate month={month} today={todayDate} blockWidth={DATE_RATEBLOCK_WIDTH} />
              </Row>

              {propertiesAndRoomTypes.map(propertyAndRoomType =>
                this.generateMCRates(propertyAndRoomType.property._id, propertyAndRoomType.roomTypes)
              )}
            </Col>
          </Row>
        </Skeleton>
        {isModalRateUpdateWizardShow && (
          <MCModalRateUpdateWizard
            title="Rate Configuration"
            selectedPropertyId={selectedPropertyForRUW || selectedPropertyId}
            isShow={isModalRateUpdateWizardShow}
            selectedRateFilters={selectedRateFilters}
            defaultStartDate={selectedStartDateForRUW}
            defaultEndDate={selectedEndDateForRUW}
            defaultRoomType={selectedRoomTypeForRUW}
            defaultUnit={selectedUnitForRUW}
            onSuccess={this.handleOnBulkRateUpdateSuccess}
            onCancel={this.handleOnCancelRateUpdateWizardModal}
            currency={guard(
              () => propertiesAndRoomTypes.find(propertyAndRoomType => propertyAndRoomType.property._id === selectedPropertyId).property.currency,
              'N/A'
            )}
            timezone={guard(
              () => propertiesAndRoomTypes.find(propertyAndRoomType => propertyAndRoomType.property._id === selectedPropertyId).property.timezone,
              'N/A'
            )}
          />
        )}
        {isModalBlockWizardShow && (
          <McModalBlockWizard
            isShow={isModalBlockWizardShow}
            selectedPropertyId={selectedPropertyId}
            selectedRateFilters={selectedRateFilters}
            onCancel={this.handleOnCancelBlockWizardModal}
            handleOnMCBlockDateActionSuccess={this.handleOnMCBlockDateActionSuccess}
            onlyMYGA={['63574217bd08dd57e0cf3b11', '5e2692104be7845599b3d2fe'].includes(selectedHostId.toString())} //TODO to apply to all host once approved
          />
        )}
      </Card>
    );
  }
}

export default DragDropContext(HTML5Backend)(withAppContext(MultiCalendar));
