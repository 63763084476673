import { httpClient } from '../httpClient';
import { apiErrorHandler } from './helpers';

export const getUserRoomTypes = ({ propertyId }) =>
  httpClient
    .get('/roomtype', { params: { property: propertyId } })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getRoomTypesByHostIdGroupedByProperty = hostId =>
  httpClient
    .get(`/roomtype/groupByProperty`, { params: { hostId } })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateRoomType = (id, payload) =>
  httpClient
    .put(`/roomtype/${id}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const postCreateRoomType = payload =>
  httpClient
    .post('/roomtype', payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteRemoveRoomTypeValidation = id =>
  httpClient
    .delete(`/roomtype/${id}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteRoomType = (id, payload) =>
  httpClient
    .patch(`/roomtype/delete-reason/${id}`, { deletedReason: payload })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getRoomTypesAndUnits = (propertyId, startDate, endDate) =>
  httpClient
    .get(`roomType/${propertyId}/roomTypesAndUnits`, { params: { startDate: startDate, endDate: endDate } })
    .then(response => response.data)
    .catch(apiErrorHandler);
