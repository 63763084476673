import { httpClient } from './../httpClient';
import { apiErrorHandler, constructFieldsToString } from './helpers';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';

export const postCreateUnit = payload =>
  httpClient
    .post('/unit', payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getUnits = (propertyId, { fields } = {}) =>
  httpClient
    .get('/unit', { params: { property: propertyId, ...(!!fields && constructFieldsToString(fields)) } })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getUnitsWithSyncStatus = (propertyId, { fields } = {}) =>
  httpClient
    .get('/unit/sync-status', { params: { property: propertyId, ...(!!fields && constructFieldsToString(fields)) } })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getUserUnitListings = () => {
  return httpClient
    .get('/unit/listing')
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getUserUnitRentals = () => {
  return httpClient
    .get('/unit/rental')
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getUnitDetails = id =>
  httpClient
    .get(`/unit/listing/${id}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getRelatedRoomTypeByUnitId = id =>
  httpClient
    .get(`/unit/listing/roomType/${id}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getUnitOwners = id =>
  httpClient
    .get(`/unit/${id}/owners`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateUnit = (id, payload) =>
  httpClient
    .put(`/unit/${id}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateUnitConfig = (id, payload) =>
  httpClient
    .put(`/unit/${id}/config`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putRentalUnit = (id, payload) =>
  httpClient
    .put(`/unit/rental/${id}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteRemoveUnitValidation = id => httpClient.delete(`/unit/${id}`).then(response => response.data); // NOTE: This endpoint doesn't have .catch because it needs to have a custom error handler in the page.

export const deleteUnit = (id, payload) =>
  httpClient
    .patch(`/unit/delete-reason/${id}`, { deletedReason: payload })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getRelationships = id =>
  httpClient
    .get(`/unit/${id}/relationship`)
    .then(res => res.data)
    .catch(apiErrorHandler);

export const getPotentialParentList = id =>
  httpClient
    .get(`/unit/${id}/parentList`)
    .then(res => res.data)
    .catch(apiErrorHandler);

export const getPotentiaChildList = id =>
  httpClient
    .get(`/unit/${id}/childList`)
    .then(res => res.data)
    .catch(apiErrorHandler);

export const updateParentChild = (id, children) =>
  httpClient
    .patch(`/unit/${id}/setChildren`, children || [])
    .then(res => res.data)
    .catch(apiErrorHandler);

export const patchUpdateUnitRoomType = (unitId, roomTypeId) =>
  httpClient
    .patch(`/unit/${unitId}/roomType`, { roomTypeId })
    .then(res => res.data)
    .catch(apiErrorHandler);

// ======================= hooks =============================== //

export const useGetPaginatedUnitListings = query => {
  const getPaginatedUnitListings = async (key, query) => {
    return httpClient
      .get('/unit/paginated', { params: query })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('units', getPaginatedUnitListings, query);
};

export const useGetPaginatedUnitRentals = query => {
  const getUserUnitRentals = async (key, query) => {
    return httpClient
      .get('/unit/rental', { params: query })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('units', getUserUnitRentals, query);
};

export const useGetUnitListings = query => {
  const getUserUnitListings = () => {
    return httpClient
      .get('/unit/listing')
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getUserUnitListings', [], getUserUnitListings, { shouldDefaultEmptyObject: false });
};

export const useGetUnitDetails = (id, { isIncludeUnitRelation } = {}) => {
  const getUnitDetails = (key, id, isIncludeUnitRelation) =>
    httpClient
      .get(`/unit/listing/${id}`, { params: { isIncludeUnitRelation } })
      .then(response => response.data)
      .catch(apiErrorHandler);

  return useCustomQuery('getUnitDetails', [id, isIncludeUnitRelation], getUnitDetails, { shouldDefaultEmptyObject: false });
};

// ========================================ADMIN
// ----------------------------GET
export const useGetUnitsWithoutPrimary = (hostId, unitId, postProcessFunc) => {
  const getUnitsWithouPrimary = (key, hostId, unitId) =>
    httpClient
      .get(`/admin/unit/host/${hostId}`, { params: { unitId } })
      .then(response => response.data)
      .catch(apiErrorHandler);

  return useCustomQuery('getUnitsWithouPrimary', [hostId, unitId], getUnitsWithouPrimary, { shouldDefaultEmptyObject: false, postProcessFunc });
};

// ----------------------------UPDATE
export const putUpdateHostProtect = (id, payload) =>
  httpClient
    .put(`/admin/unit/${id}/protection`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);
