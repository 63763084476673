import React, { Component } from 'react';
import { message, Modal, Card as AntdCard, Form, Input, Button, DatePicker, Avatar, Row, Select, Switch, Tabs, Tooltip, Icon, Radio } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { REACT_APP_ENDPOINT } from 'config/env';

import { withAppContext } from 'context/AppContext';
import { getConstants } from 'utils/apis/constants';
import {
  createHost,
  getHostById,
  updateHost,
  deleteHost,
  getHostsWithoutParent,
  getStripeConnectAccountLink,
  patchStripeConnectAccountId
} from 'utils/apis/host';
import { CONTACT_NUMBER_REGEX, EMAIL_REGEX } from 'utils/constants';
import { guard } from 'utils/general';

import Card from 'components/Card/Card';
import CloseButton from 'components/CloseButton/CloseButton';
import LocationCard from 'views/ListingDetails/Property/components/LocationCard/LocationCard.js';
import HostRelationshipCard from 'views/Host/components/HostRelationshipCard/HostRelationshipCard';
import HostBookingEngineConfigCard from 'views/Host/components/HostBookingEngineConfigCard/HostBookingEngineConfigCard';

import './HostForm.css';

const FormItem = Form.Item;
const { Option } = Select;
const TabPane = Tabs.TabPane;

const S3_URL = `https://${process.env.REACT_APP_S3_IMAGES_BUCKET || 'nebula-local-images'}.s3.amazonaws.com/`;
const UPLOAD_OPTIONS = {
  server: REACT_APP_ENDPOINT,
  signingUrlQueryParams: { uploadType: 'avatar' }
};

class HostForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'new',
      bookingEngineBackground: '',
      images: [],
      isLoading: true,
      hasFetchedStates: false,
      hasFetchedCountries: false,
      hostTypeOptions: [],
      partnerStatusOptions: [],
      hostStatusOptions: [],
      countryOptions: [],
      city: [],
      statesSelection: [],
      countriesSelection: [],
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      childrenList: [],
      children: [],
      allowHostChargesInServicePackage: false,
      allowRentalListing: false,
      allowBookingEngine: false,
      allowHotelBookingEngine: false,
      showStaySuitesInfo: false,
      gmtList: [
        {
          value: 'Dateline Standard Time',
          abbr: 'DST',
          offset: -12,
          isdst: false,
          text: '(UTC-12:00) International Date Line West',
          utc: ['Etc/GMT+12']
        },
        {
          value: 'UTC-11',
          abbr: 'U',
          offset: -11,
          isdst: false,
          text: '(UTC-11:00) Coordinated Universal Time-11',
          utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago']
        },
        {
          value: 'Hawaiian Standard Time',
          abbr: 'HST',
          offset: -10,
          isdst: false,
          text: '(UTC-10:00) Hawaii',
          utc: ['Etc/GMT+10', 'Pacific/Honolulu', 'Pacific/Johnston', 'Pacific/Rarotonga', 'Pacific/Tahiti']
        },
        {
          value: 'Alaskan Standard Time',
          abbr: 'AKDT',
          offset: -8,
          isdst: true,
          text: '(UTC-09:00) Alaska',
          utc: ['America/Anchorage', 'America/Juneau', 'America/Nome', 'America/Sitka', 'America/Yakutat']
        },
        {
          value: 'Pacific Standard Time (Mexico)',
          abbr: 'PDT',
          offset: -7,
          isdst: true,
          text: '(UTC-08:00) Baja California',
          utc: ['America/Santa_Isabel']
        },
        {
          value: 'Pacific Daylight Time',
          abbr: 'PDT',
          offset: -7,
          isdst: true,
          text: '(UTC-07:00) Pacific Daylight Time (US & Canada)',
          utc: ['America/Los_Angeles', 'America/Tijuana', 'America/Vancouver']
        },
        {
          value: 'Pacific Standard Time',
          abbr: 'PST',
          offset: -8,
          isdst: false,
          text: '(UTC-08:00) Pacific Standard Time (US & Canada)',
          utc: ['America/Los_Angeles', 'America/Tijuana', 'America/Vancouver', 'PST8PDT']
        },
        {
          value: 'US Mountain Standard Time',
          abbr: 'UMST',
          offset: -7,
          isdst: false,
          text: '(UTC-07:00) Arizona',
          utc: [
            'America/Creston',
            'America/Dawson',
            'America/Dawson_Creek',
            'America/Hermosillo',
            'America/Phoenix',
            'America/Whitehorse',
            'Etc/GMT+7'
          ]
        },
        {
          value: 'Mountain Standard Time (Mexico)',
          abbr: 'MDT',
          offset: -6,
          isdst: true,
          text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
          utc: ['America/Chihuahua', 'America/Mazatlan']
        },
        {
          value: 'Mountain Standard Time',
          abbr: 'MDT',
          offset: -6,
          isdst: true,
          text: '(UTC-07:00) Mountain Time (US & Canada)',
          utc: [
            'America/Boise',
            'America/Cambridge_Bay',
            'America/Denver',
            'America/Edmonton',
            'America/Inuvik',
            'America/Ojinaga',
            'America/Yellowknife',
            'MST7MDT'
          ]
        },
        {
          value: 'Central America Standard Time',
          abbr: 'CAST',
          offset: -6,
          isdst: false,
          text: '(UTC-06:00) Central America',
          utc: [
            'America/Belize',
            'America/Costa_Rica',
            'America/El_Salvador',
            'America/Guatemala',
            'America/Managua',
            'America/Tegucigalpa',
            'Etc/GMT+6',
            'Pacific/Galapagos'
          ]
        },
        {
          value: 'Central Standard Time',
          abbr: 'CDT',
          offset: -5,
          isdst: true,
          text: '(UTC-06:00) Central Time (US & Canada)',
          utc: [
            'America/Chicago',
            'America/Indiana/Knox',
            'America/Indiana/Tell_City',
            'America/Matamoros',
            'America/Menominee',
            'America/North_Dakota/Beulah',
            'America/North_Dakota/Center',
            'America/North_Dakota/New_Salem',
            'America/Rainy_River',
            'America/Rankin_Inlet',
            'America/Resolute',
            'America/Winnipeg',
            'CST6CDT'
          ]
        },
        {
          value: 'Central Standard Time (Mexico)',
          abbr: 'CDT',
          offset: -5,
          isdst: true,
          text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
          utc: ['America/Bahia_Banderas', 'America/Cancun', 'America/Merida', 'America/Mexico_City', 'America/Monterrey']
        },
        {
          value: 'Canada Central Standard Time',
          abbr: 'CCST',
          offset: -6,
          isdst: false,
          text: '(UTC-06:00) Saskatchewan',
          utc: ['America/Regina', 'America/Swift_Current']
        },
        {
          value: 'SA Pacific Standard Time',
          abbr: 'SPST',
          offset: -5,
          isdst: false,
          text: '(UTC-05:00) Bogota, Lima, Quito',
          utc: [
            'America/Bogota',
            'America/Cayman',
            'America/Coral_Harbour',
            'America/Eirunepe',
            'America/Guayaquil',
            'America/Jamaica',
            'America/Lima',
            'America/Panama',
            'America/Rio_Branco',
            'Etc/GMT+5'
          ]
        },
        {
          value: 'Eastern Standard Time',
          abbr: 'EST',
          offset: -5,
          isdst: false,
          text: '(UTC-05:00) Eastern Time (US & Canada)',
          utc: [
            'America/Detroit',
            'America/Havana',
            'America/Indiana/Petersburg',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Iqaluit',
            'America/Kentucky/Monticello',
            'America/Louisville',
            'America/Montreal',
            'America/Nassau',
            'America/New_York',
            'America/Nipigon',
            'America/Pangnirtung',
            'America/Port-au-Prince',
            'America/Thunder_Bay',
            'America/Toronto'
          ]
        },
        {
          value: 'Eastern Daylight Time',
          abbr: 'EDT',
          offset: -4,
          isdst: true,
          text: '(UTC-04:00) Eastern Daylight Time (US & Canada)',
          utc: [
            'America/Detroit',
            'America/Havana',
            'America/Indiana/Petersburg',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Iqaluit',
            'America/Kentucky/Monticello',
            'America/Louisville',
            'America/Montreal',
            'America/Nassau',
            'America/New_York',
            'America/Nipigon',
            'America/Pangnirtung',
            'America/Port-au-Prince',
            'America/Thunder_Bay',
            'America/Toronto'
          ]
        },
        {
          value: 'US Eastern Standard Time',
          abbr: 'UEDT',
          offset: -5,
          isdst: false,
          text: '(UTC-05:00) Indiana (East)',
          utc: ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis']
        },
        {
          value: 'Venezuela Standard Time',
          abbr: 'VST',
          offset: -4.5,
          isdst: false,
          text: '(UTC-04:30) Caracas',
          utc: ['America/Caracas']
        },
        {
          value: 'Paraguay Standard Time',
          abbr: 'PYT',
          offset: -4,
          isdst: false,
          text: '(UTC-04:00) Asuncion',
          utc: ['America/Asuncion']
        },
        {
          value: 'Atlantic Standard Time',
          abbr: 'ADT',
          offset: -3,
          isdst: true,
          text: '(UTC-04:00) Atlantic Time (Canada)',
          utc: ['America/Glace_Bay', 'America/Goose_Bay', 'America/Halifax', 'America/Moncton', 'America/Thule', 'Atlantic/Bermuda']
        },
        {
          value: 'Central Brazilian Standard Time',
          abbr: 'CBST',
          offset: -4,
          isdst: false,
          text: '(UTC-04:00) Cuiaba',
          utc: ['America/Campo_Grande', 'America/Cuiaba']
        },
        {
          value: 'SA Western Standard Time',
          abbr: 'SWST',
          offset: -4,
          isdst: false,
          text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
          utc: [
            'America/Anguilla',
            'America/Antigua',
            'America/Aruba',
            'America/Barbados',
            'America/Blanc-Sablon',
            'America/Boa_Vista',
            'America/Curacao',
            'America/Dominica',
            'America/Grand_Turk',
            'America/Grenada',
            'America/Guadeloupe',
            'America/Guyana',
            'America/Kralendijk',
            'America/La_Paz',
            'America/Lower_Princes',
            'America/Manaus',
            'America/Marigot',
            'America/Martinique',
            'America/Montserrat',
            'America/Port_of_Spain',
            'America/Porto_Velho',
            'America/Puerto_Rico',
            'America/Santo_Domingo',
            'America/St_Barthelemy',
            'America/St_Kitts',
            'America/St_Lucia',
            'America/St_Thomas',
            'America/St_Vincent',
            'America/Tortola',
            'Etc/GMT+4'
          ]
        },
        {
          value: 'Pacific SA Standard Time',
          abbr: 'PSST',
          offset: -4,
          isdst: false,
          text: '(UTC-04:00) Santiago',
          utc: ['America/Santiago', 'Antarctica/Palmer']
        },
        {
          value: 'Newfoundland Standard Time',
          abbr: 'NDT',
          offset: -2.5,
          isdst: true,
          text: '(UTC-03:30) Newfoundland',
          utc: ['America/St_Johns']
        },
        {
          value: 'E. South America Standard Time',
          abbr: 'ESAST',
          offset: -3,
          isdst: false,
          text: '(UTC-03:00) Brasilia',
          utc: ['America/Sao_Paulo']
        },
        {
          value: 'Argentina Standard Time',
          abbr: 'AST',
          offset: -3,
          isdst: false,
          text: '(UTC-03:00) Buenos Aires',
          utc: [
            'America/Argentina/Buenos_Aires',
            'America/Argentina/Catamarca',
            'America/Argentina/Cordoba',
            'America/Argentina/Jujuy',
            'America/Argentina/La_Rioja',
            'America/Argentina/Mendoza',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Salta',
            'America/Argentina/San_Juan',
            'America/Argentina/San_Luis',
            'America/Argentina/Tucuman',
            'America/Argentina/Ushuaia',
            'America/Buenos_Aires',
            'America/Catamarca',
            'America/Cordoba',
            'America/Jujuy',
            'America/Mendoza'
          ]
        },
        {
          value: 'SA Eastern Standard Time',
          abbr: 'SEST',
          offset: -3,
          isdst: false,
          text: '(UTC-03:00) Cayenne, Fortaleza',
          utc: [
            'America/Araguaina',
            'America/Belem',
            'America/Cayenne',
            'America/Fortaleza',
            'America/Maceio',
            'America/Paramaribo',
            'America/Recife',
            'America/Santarem',
            'Antarctica/Rothera',
            'Atlantic/Stanley',
            'Etc/GMT+3'
          ]
        },
        {
          value: 'Greenland Standard Time',
          abbr: 'GDT',
          offset: -3,
          isdst: true,
          text: '(UTC-03:00) Greenland',
          utc: ['America/Godthab']
        },
        {
          value: 'Montevideo Standard Time',
          abbr: 'MST',
          offset: -3,
          isdst: false,
          text: '(UTC-03:00) Montevideo',
          utc: ['America/Montevideo']
        },
        {
          value: 'Bahia Standard Time',
          abbr: 'BST',
          offset: -3,
          isdst: false,
          text: '(UTC-03:00) Salvador',
          utc: ['America/Bahia']
        },
        {
          value: 'UTC-02',
          abbr: 'U',
          offset: -2,
          isdst: false,
          text: '(UTC-02:00) Coordinated Universal Time-02',
          utc: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2']
        },
        {
          value: 'Mid-Atlantic Standard Time',
          abbr: 'MDT',
          offset: -1,
          isdst: true,
          text: '(UTC-02:00) Mid-Atlantic - Old',
          utc: []
        },
        {
          value: 'Azores Standard Time',
          abbr: 'ADT',
          offset: 0,
          isdst: true,
          text: '(UTC-01:00) Azores',
          utc: ['America/Scoresbysund', 'Atlantic/Azores']
        },
        {
          value: 'Cape Verde Standard Time',
          abbr: 'CVST',
          offset: -1,
          isdst: false,
          text: '(UTC-01:00) Cape Verde Is.',
          utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1']
        },
        {
          value: 'Morocco Standard Time',
          abbr: 'MDT',
          offset: 1,
          isdst: true,
          text: '(UTC) Casablanca',
          utc: ['Africa/Casablanca', 'Africa/El_Aaiun']
        },
        {
          value: 'UTC',
          abbr: 'UTC',
          offset: 0,
          isdst: false,
          text: '(UTC) Coordinated Universal Time',
          utc: ['America/Danmarkshavn', 'Etc/GMT']
        },
        {
          value: 'GMT Standard Time',
          abbr: 'GMT',
          offset: 0,
          isdst: false,
          text: '(UTC) Edinburgh, London',
          utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London']
        },
        {
          value: 'British Summer Time',
          abbr: 'BST',
          offset: 1,
          isdst: true,
          text: '(UTC+01:00) Edinburgh, London',
          utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London']
        },
        {
          value: 'GMT Standard Time',
          abbr: 'GDT',
          offset: 1,
          isdst: true,
          text: '(UTC) Dublin, Lisbon',
          utc: ['Atlantic/Canary', 'Atlantic/Faeroe', 'Atlantic/Madeira', 'Europe/Dublin', 'Europe/Lisbon']
        },
        {
          value: 'Greenwich Standard Time',
          abbr: 'GST',
          offset: 0,
          isdst: false,
          text: '(UTC) Monrovia, Reykjavik',
          utc: [
            'Africa/Abidjan',
            'Africa/Accra',
            'Africa/Bamako',
            'Africa/Banjul',
            'Africa/Bissau',
            'Africa/Conakry',
            'Africa/Dakar',
            'Africa/Freetown',
            'Africa/Lome',
            'Africa/Monrovia',
            'Africa/Nouakchott',
            'Africa/Ouagadougou',
            'Africa/Sao_Tome',
            'Atlantic/Reykjavik',
            'Atlantic/St_Helena'
          ]
        },
        {
          value: 'W. Europe Standard Time',
          abbr: 'WEDT',
          offset: 2,
          isdst: true,
          text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
          utc: [
            'Arctic/Longyearbyen',
            'Europe/Amsterdam',
            'Europe/Andorra',
            'Europe/Berlin',
            'Europe/Busingen',
            'Europe/Gibraltar',
            'Europe/Luxembourg',
            'Europe/Malta',
            'Europe/Monaco',
            'Europe/Oslo',
            'Europe/Rome',
            'Europe/San_Marino',
            'Europe/Stockholm',
            'Europe/Vaduz',
            'Europe/Vatican',
            'Europe/Vienna',
            'Europe/Zurich'
          ]
        },
        {
          value: 'Central Europe Standard Time',
          abbr: 'CEDT',
          offset: 2,
          isdst: true,
          text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
          utc: ['Europe/Belgrade', 'Europe/Bratislava', 'Europe/Budapest', 'Europe/Ljubljana', 'Europe/Podgorica', 'Europe/Prague', 'Europe/Tirane']
        },
        {
          value: 'Romance Standard Time',
          abbr: 'RDT',
          offset: 2,
          isdst: true,
          text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
          utc: ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Europe/Paris']
        },
        {
          value: 'Central European Standard Time',
          abbr: 'CEDT',
          offset: 2,
          isdst: true,
          text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
          utc: ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb']
        },
        {
          value: 'W. Central Africa Standard Time',
          abbr: 'WCAST',
          offset: 1,
          isdst: false,
          text: '(UTC+01:00) West Central Africa',
          utc: [
            'Africa/Algiers',
            'Africa/Bangui',
            'Africa/Brazzaville',
            'Africa/Douala',
            'Africa/Kinshasa',
            'Africa/Lagos',
            'Africa/Libreville',
            'Africa/Luanda',
            'Africa/Malabo',
            'Africa/Ndjamena',
            'Africa/Niamey',
            'Africa/Porto-Novo',
            'Africa/Tunis',
            'Etc/GMT-1'
          ]
        },
        {
          value: 'Namibia Standard Time',
          abbr: 'NST',
          offset: 1,
          isdst: false,
          text: '(UTC+01:00) Windhoek',
          utc: ['Africa/Windhoek']
        },
        {
          value: 'GTB Standard Time',
          abbr: 'GDT',
          offset: 3,
          isdst: true,
          text: '(UTC+02:00) Athens, Bucharest',
          utc: ['Asia/Nicosia', 'Europe/Athens', 'Europe/Bucharest', 'Europe/Chisinau']
        },
        {
          value: 'Middle East Standard Time',
          abbr: 'MEDT',
          offset: 3,
          isdst: true,
          text: '(UTC+02:00) Beirut',
          utc: ['Asia/Beirut']
        },
        {
          value: 'Egypt Standard Time',
          abbr: 'EST',
          offset: 2,
          isdst: false,
          text: '(UTC+02:00) Cairo',
          utc: ['Africa/Cairo']
        },
        {
          value: 'Syria Standard Time',
          abbr: 'SDT',
          offset: 3,
          isdst: true,
          text: '(UTC+02:00) Damascus',
          utc: ['Asia/Damascus']
        },
        {
          value: 'E. Europe Standard Time',
          abbr: 'EEDT',
          offset: 3,
          isdst: true,
          text: '(UTC+02:00) E. Europe',
          utc: [
            'Asia/Nicosia',
            'Europe/Athens',
            'Europe/Bucharest',
            'Europe/Chisinau',
            'Europe/Helsinki',
            'Europe/Kyiv',
            'Europe/Mariehamn',
            'Europe/Nicosia',
            'Europe/Riga',
            'Europe/Sofia',
            'Europe/Tallinn',
            'Europe/Uzhgorod',
            'Europe/Vilnius',
            'Europe/Zaporozhye'
          ]
        },
        {
          value: 'South Africa Standard Time',
          abbr: 'SAST',
          offset: 2,
          isdst: false,
          text: '(UTC+02:00) Harare, Pretoria',
          utc: [
            'Africa/Blantyre',
            'Africa/Bujumbura',
            'Africa/Gaborone',
            'Africa/Harare',
            'Africa/Johannesburg',
            'Africa/Kigali',
            'Africa/Lubumbashi',
            'Africa/Lusaka',
            'Africa/Maputo',
            'Africa/Maseru',
            'Africa/Mbabane',
            'Etc/GMT-2'
          ]
        },
        {
          value: 'FLE Standard Time',
          abbr: 'FDT',
          offset: 3,
          isdst: true,
          text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
          utc: [
            'Europe/Helsinki',
            'Europe/Kyiv',
            'Europe/Mariehamn',
            'Europe/Riga',
            'Europe/Sofia',
            'Europe/Tallinn',
            'Europe/Uzhgorod',
            'Europe/Vilnius',
            'Europe/Zaporozhye'
          ]
        },
        {
          value: 'Turkey Standard Time',
          abbr: 'TDT',
          offset: 3,
          isdst: false,
          text: '(UTC+03:00) Istanbul',
          utc: ['Europe/Istanbul']
        },
        {
          value: 'Israel Standard Time',
          abbr: 'JDT',
          offset: 3,
          isdst: true,
          text: '(UTC+02:00) Jerusalem',
          utc: ['Asia/Jerusalem']
        },
        {
          value: 'Libya Standard Time',
          abbr: 'LST',
          offset: 2,
          isdst: false,
          text: '(UTC+02:00) Tripoli',
          utc: ['Africa/Tripoli']
        },
        {
          value: 'Jordan Standard Time',
          abbr: 'JST',
          offset: 3,
          isdst: false,
          text: '(UTC+03:00) Amman',
          utc: ['Asia/Amman']
        },
        {
          value: 'Arabic Standard Time',
          abbr: 'AST',
          offset: 3,
          isdst: false,
          text: '(UTC+03:00) Baghdad',
          utc: ['Asia/Baghdad']
        },
        {
          value: 'Kaliningrad Standard Time',
          abbr: 'KST',
          offset: 3,
          isdst: false,
          text: '(UTC+02:00) Kaliningrad',
          utc: ['Europe/Kaliningrad']
        },
        {
          value: 'Arab Standard Time',
          abbr: 'AST',
          offset: 3,
          isdst: false,
          text: '(UTC+03:00) Kuwait, Riyadh',
          utc: ['Asia/Aden', 'Asia/Bahrain', 'Asia/Kuwait', 'Asia/Qatar', 'Asia/Riyadh']
        },
        {
          value: 'E. Africa Standard Time',
          abbr: 'EAST',
          offset: 3,
          isdst: false,
          text: '(UTC+03:00) Nairobi',
          utc: [
            'Africa/Addis_Ababa',
            'Africa/Asmera',
            'Africa/Dar_es_Salaam',
            'Africa/Djibouti',
            'Africa/Juba',
            'Africa/Kampala',
            'Africa/Khartoum',
            'Africa/Mogadishu',
            'Africa/Nairobi',
            'Antarctica/Syowa',
            'Etc/GMT-3',
            'Indian/Antananarivo',
            'Indian/Comoro',
            'Indian/Mayotte'
          ]
        },
        {
          value: 'Moscow Standard Time',
          abbr: 'MSK',
          offset: 3,
          isdst: false,
          text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
          utc: ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd', 'Europe/Minsk']
        },
        {
          value: 'Samara Time',
          abbr: 'SAMT',
          offset: 4,
          isdst: false,
          text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
          utc: ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk']
        },
        {
          value: 'Iran Standard Time',
          abbr: 'IDT',
          offset: 4.5,
          isdst: true,
          text: '(UTC+03:30) Tehran',
          utc: ['Asia/Tehran']
        },
        {
          value: 'Arabian Standard Time',
          abbr: 'AST',
          offset: 4,
          isdst: false,
          text: '(UTC+04:00) Abu Dhabi, Muscat',
          utc: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4']
        },
        {
          value: 'Azerbaijan Standard Time',
          abbr: 'ADT',
          offset: 5,
          isdst: true,
          text: '(UTC+04:00) Baku',
          utc: ['Asia/Baku']
        },
        {
          value: 'Mauritius Standard Time',
          abbr: 'MST',
          offset: 4,
          isdst: false,
          text: '(UTC+04:00) Port Louis',
          utc: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion']
        },
        {
          value: 'Georgian Standard Time',
          abbr: 'GET',
          offset: 4,
          isdst: false,
          text: '(UTC+04:00) Tbilisi',
          utc: ['Asia/Tbilisi']
        },
        {
          value: 'Caucasus Standard Time',
          abbr: 'CST',
          offset: 4,
          isdst: false,
          text: '(UTC+04:00) Yerevan',
          utc: ['Asia/Yerevan']
        },
        {
          value: 'Afghanistan Standard Time',
          abbr: 'AST',
          offset: 4.5,
          isdst: false,
          text: '(UTC+04:30) Kabul',
          utc: ['Asia/Kabul']
        },
        {
          value: 'West Asia Standard Time',
          abbr: 'WAST',
          offset: 5,
          isdst: false,
          text: '(UTC+05:00) Ashgabat, Tashkent',
          utc: [
            'Antarctica/Mawson',
            'Asia/Aqtau',
            'Asia/Aqtobe',
            'Asia/Ashgabat',
            'Asia/Dushanbe',
            'Asia/Oral',
            'Asia/Samarkand',
            'Asia/Tashkent',
            'Etc/GMT-5',
            'Indian/Kerguelen',
            'Indian/Maldives'
          ]
        },
        {
          value: 'Yekaterinburg Time',
          abbr: 'YEKT',
          offset: 5,
          isdst: false,
          text: '(UTC+05:00) Yekaterinburg',
          utc: ['Asia/Yekaterinburg']
        },
        {
          value: 'Pakistan Standard Time',
          abbr: 'PKT',
          offset: 5,
          isdst: false,
          text: '(UTC+05:00) Islamabad, Karachi',
          utc: ['Asia/Karachi']
        },
        {
          value: 'India Standard Time',
          abbr: 'IST',
          offset: 5.5,
          isdst: false,
          text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
          utc: ['Asia/Kolkata', 'Asia/Calcutta']
        },
        {
          value: 'Sri Lanka Standard Time',
          abbr: 'SLST',
          offset: 5.5,
          isdst: false,
          text: '(UTC+05:30) Sri Jayawardenepura',
          utc: ['Asia/Colombo']
        },
        {
          value: 'Nepal Standard Time',
          abbr: 'NST',
          offset: 5.75,
          isdst: false,
          text: '(UTC+05:45) Kathmandu',
          utc: ['Asia/Kathmandu']
        },
        {
          value: 'Central Asia Standard Time',
          abbr: 'CAST',
          offset: 6,
          isdst: false,
          text: '(UTC+06:00) Nur-Sultan (Astana)',
          utc: ['Antarctica/Vostok', 'Asia/Almaty', 'Asia/Bishkek', 'Asia/Qyzylorda', 'Asia/Urumqi', 'Etc/GMT-6', 'Indian/Chagos']
        },
        {
          value: 'Bangladesh Standard Time',
          abbr: 'BST',
          offset: 6,
          isdst: false,
          text: '(UTC+06:00) Dhaka',
          utc: ['Asia/Dhaka', 'Asia/Thimphu']
        },
        {
          value: 'Myanmar Standard Time',
          abbr: 'MST',
          offset: 6.5,
          isdst: false,
          text: '(UTC+06:30) Yangon (Rangoon)',
          utc: ['Asia/Rangoon', 'Indian/Cocos']
        },
        {
          value: 'SE Asia Standard Time',
          abbr: 'SAST',
          offset: 7,
          isdst: false,
          text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
          utc: [
            'Antarctica/Davis',
            'Asia/Bangkok',
            'Asia/Hovd',
            'Asia/Jakarta',
            'Asia/Phnom_Penh',
            'Asia/Pontianak',
            'Asia/Saigon',
            'Asia/Vientiane',
            'Etc/GMT-7',
            'Indian/Christmas'
          ]
        },
        {
          value: 'N. Central Asia Standard Time',
          abbr: 'NCAST',
          offset: 7,
          isdst: false,
          text: '(UTC+07:00) Novosibirsk',
          utc: ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk']
        },
        {
          value: 'China Standard Time',
          abbr: 'CST',
          offset: 8,
          isdst: false,
          text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
          utc: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai']
        },
        {
          value: 'North Asia Standard Time',
          abbr: 'NAST',
          offset: 8,
          isdst: false,
          text: '(UTC+08:00) Krasnoyarsk',
          utc: ['Asia/Krasnoyarsk']
        },
        {
          value: 'Singapore Standard Time',
          abbr: 'MPST',
          offset: 8,
          isdst: false,
          text: '(UTC+08:00) Kuala Lumpur, Singapore',
          utc: ['Asia/Brunei', 'Asia/Kuala_Lumpur', 'Asia/Kuching', 'Asia/Makassar', 'Asia/Manila', 'Asia/Singapore', 'Etc/GMT-8']
        },
        {
          value: 'W. Australia Standard Time',
          abbr: 'WAST',
          offset: 8,
          isdst: false,
          text: '(UTC+08:00) Perth',
          utc: ['Antarctica/Casey', 'Australia/Perth']
        },
        {
          value: 'Taipei Standard Time',
          abbr: 'TST',
          offset: 8,
          isdst: false,
          text: '(UTC+08:00) Taipei',
          utc: ['Asia/Taipei']
        },
        {
          value: 'Ulaanbaatar Standard Time',
          abbr: 'UST',
          offset: 8,
          isdst: false,
          text: '(UTC+08:00) Ulaanbaatar',
          utc: ['Asia/Choibalsan', 'Asia/Ulaanbaatar']
        },
        {
          value: 'North Asia East Standard Time',
          abbr: 'NAEST',
          offset: 8,
          isdst: false,
          text: '(UTC+08:00) Irkutsk',
          utc: ['Asia/Irkutsk']
        },
        {
          value: 'Japan Standard Time',
          abbr: 'JST',
          offset: 9,
          isdst: false,
          text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
          utc: ['Asia/Dili', 'Asia/Jayapura', 'Asia/Tokyo', 'Etc/GMT-9', 'Pacific/Palau']
        },
        {
          value: 'Korea Standard Time',
          abbr: 'KST',
          offset: 9,
          isdst: false,
          text: '(UTC+09:00) Seoul',
          utc: ['Asia/Pyongyang', 'Asia/Seoul']
        },
        {
          value: 'Cen. Australia Standard Time',
          abbr: 'CAST',
          offset: 9.5,
          isdst: false,
          text: '(UTC+09:30) Adelaide',
          utc: ['Australia/Adelaide', 'Australia/Broken_Hill']
        },
        {
          value: 'AUS Central Standard Time',
          abbr: 'ACST',
          offset: 9.5,
          isdst: false,
          text: '(UTC+09:30) Darwin',
          utc: ['Australia/Darwin']
        },
        {
          value: 'E. Australia Standard Time',
          abbr: 'EAST',
          offset: 10,
          isdst: false,
          text: '(UTC+10:00) Brisbane',
          utc: ['Australia/Brisbane', 'Australia/Lindeman']
        },
        {
          value: 'AUS Eastern Standard Time',
          abbr: 'AEST',
          offset: 10,
          isdst: false,
          text: '(UTC+10:00) Canberra, Melbourne, Sydney',
          utc: ['Australia/Melbourne', 'Australia/Sydney']
        },
        {
          value: 'West Pacific Standard Time',
          abbr: 'WPST',
          offset: 10,
          isdst: false,
          text: '(UTC+10:00) Guam, Port Moresby',
          utc: ['Antarctica/DumontDUrville', 'Etc/GMT-10', 'Pacific/Guam', 'Pacific/Port_Moresby', 'Pacific/Saipan', 'Pacific/Truk']
        },
        {
          value: 'Tasmania Standard Time',
          abbr: 'TST',
          offset: 10,
          isdst: false,
          text: '(UTC+10:00) Hobart',
          utc: ['Australia/Currie', 'Australia/Hobart']
        },
        {
          value: 'Yakutsk Standard Time',
          abbr: 'YST',
          offset: 9,
          isdst: false,
          text: '(UTC+09:00) Yakutsk',
          utc: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk']
        },
        {
          value: 'Central Pacific Standard Time',
          abbr: 'CPST',
          offset: 11,
          isdst: false,
          text: '(UTC+11:00) Solomon Is., New Caledonia',
          utc: ['Antarctica/Macquarie', 'Etc/GMT-11', 'Pacific/Efate', 'Pacific/Guadalcanal', 'Pacific/Kosrae', 'Pacific/Noumea', 'Pacific/Ponape']
        },
        {
          value: 'Vladivostok Standard Time',
          abbr: 'VST',
          offset: 11,
          isdst: false,
          text: '(UTC+11:00) Vladivostok',
          utc: ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok']
        },
        {
          value: 'New Zealand Standard Time',
          abbr: 'NZST',
          offset: 12,
          isdst: false,
          text: '(UTC+12:00) Auckland, Wellington',
          utc: ['Antarctica/McMurdo', 'Pacific/Auckland']
        },
        {
          value: 'UTC+12',
          abbr: 'U',
          offset: 12,
          isdst: false,
          text: '(UTC+12:00) Coordinated Universal Time+12',
          utc: [
            'Etc/GMT-12',
            'Pacific/Funafuti',
            'Pacific/Kwajalein',
            'Pacific/Majuro',
            'Pacific/Nauru',
            'Pacific/Tarawa',
            'Pacific/Wake',
            'Pacific/Wallis'
          ]
        },
        {
          value: 'Fiji Standard Time',
          abbr: 'FST',
          offset: 12,
          isdst: false,
          text: '(UTC+12:00) Fiji',
          utc: ['Pacific/Fiji']
        },
        {
          value: 'Magadan Standard Time',
          abbr: 'MST',
          offset: 12,
          isdst: false,
          text: '(UTC+12:00) Magadan',
          utc: ['Asia/Anadyr', 'Asia/Kamchatka', 'Asia/Magadan', 'Asia/Srednekolymsk']
        },
        {
          value: 'Kamchatka Standard Time',
          abbr: 'KDT',
          offset: 13,
          isdst: true,
          text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
          utc: ['Asia/Kamchatka']
        },
        {
          value: 'Tonga Standard Time',
          abbr: 'TST',
          offset: 13,
          isdst: false,
          text: "(UTC+13:00) Nuku'alofa",
          utc: ['Etc/GMT-13', 'Pacific/Enderbury', 'Pacific/Fakaofo', 'Pacific/Tongatapu']
        },
        {
          value: 'Samoa Standard Time',
          abbr: 'SST',
          offset: 13,
          isdst: false,
          text: '(UTC+13:00) Samoa',
          utc: ['Pacific/Apia']
        }
      ],
      currencyList: [
        { cc: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
        { cc: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
        { cc: 'ALL', symbol: 'L', name: 'Albanian lek' },
        { cc: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
        { cc: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
        { cc: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
        { cc: 'ARS', symbol: '$', name: 'Argentine peso' },
        { cc: 'AUD', symbol: '$', name: 'Australian dollar' },
        { cc: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
        { cc: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
        { cc: 'BAM', symbol: 'KM', name: 'Bosnia and Herzegovina konvertibilna marka' },
        { cc: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
        { cc: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
        { cc: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
        { cc: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
        { cc: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
        { cc: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
        { cc: 'BND', symbol: 'B$', name: 'Brunei dollar' },
        { cc: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
        { cc: 'BRL', symbol: 'R$', name: 'Brazilian real' },
        { cc: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
        { cc: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
        { cc: 'BWP', symbol: 'P', name: 'Botswana pula' },
        { cc: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
        { cc: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
        { cc: 'CAD', symbol: '$', name: 'Canadian dollar' },
        { cc: 'CDF', symbol: 'F', name: 'Congolese franc' },
        { cc: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
        { cc: 'CLP', symbol: '$', name: 'Chilean peso' },
        { cc: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
        { cc: 'COP', symbol: 'Col$', name: 'Colombian peso' },
        { cc: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
        { cc: 'CUC', symbol: '$', name: 'Cuban peso' },
        { cc: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
        { cc: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
        { cc: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
        { cc: 'DKK', symbol: 'Kr', name: 'Danish krone' },
        { cc: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
        { cc: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
        { cc: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
        { cc: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
        { cc: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
        { cc: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
        { cc: 'EUR', symbol: '\u20ac', name: 'European Euro' },
        { cc: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
        { cc: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
        { cc: 'GBP', symbol: '\u00a3', name: 'British pound' },
        { cc: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
        { cc: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
        { cc: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
        { cc: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
        { cc: 'GNF', symbol: 'FG', name: 'Guinean franc' },
        { cc: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
        { cc: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
        { cc: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
        { cc: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
        { cc: 'HNL', symbol: 'L', name: 'Honduran lempira' },
        { cc: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
        { cc: 'HTG', symbol: 'G', name: 'Haitian gourde' },
        { cc: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
        { cc: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
        { cc: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
        { cc: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
        { cc: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
        { cc: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
        { cc: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
        { cc: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
        { cc: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
        { cc: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
        { cc: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
        { cc: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
        { cc: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
        { cc: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
        { cc: 'KPW', symbol: 'W', name: 'North Korean won' },
        { cc: 'KRW', symbol: 'W', name: 'South Korean won' },
        { cc: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
        { cc: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
        { cc: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
        { cc: 'LAK', symbol: 'KN', name: 'Lao kip' },
        { cc: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
        { cc: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
        { cc: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
        { cc: 'LSL', symbol: 'M', name: 'Lesotho loti' },
        { cc: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
        { cc: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
        { cc: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
        { cc: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
        { cc: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
        { cc: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
        { cc: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
        { cc: 'MMK', symbol: 'K', name: 'Myanma kyat' },
        { cc: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
        { cc: 'MOP', symbol: 'P', name: 'Macanese pataca' },
        { cc: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
        { cc: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
        { cc: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
        { cc: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
        { cc: 'MXN', symbol: '$', name: 'Mexican peso' },
        { cc: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
        { cc: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
        { cc: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
        { cc: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
        { cc: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
        { cc: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
        { cc: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
        { cc: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
        { cc: 'OMR', symbol: 'OMR', name: 'Omani rial' },
        { cc: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
        { cc: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
        { cc: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
        { cc: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
        { cc: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
        { cc: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
        { cc: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
        { cc: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
        { cc: 'RON', symbol: 'L', name: 'Romanian leu' },
        { cc: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
        { cc: 'RUB', symbol: 'R', name: 'Russian ruble' },
        { cc: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
        { cc: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
        { cc: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
        { cc: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
        { cc: 'SEK', symbol: 'kr', name: 'Swedish krona' },
        { cc: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
        { cc: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
        { cc: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
        { cc: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
        { cc: 'SRD', symbol: '$', name: 'Surinamese dollar' },
        { cc: 'SYP', symbol: 'LS', name: 'Syrian pound' },
        { cc: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
        { cc: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
        { cc: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
        { cc: 'TMT', symbol: 'm', name: 'Turkmen manat' },
        { cc: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
        { cc: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
        { cc: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
        { cc: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
        { cc: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
        { cc: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
        { cc: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
        { cc: 'USD', symbol: 'US$', name: 'United States dollar' },
        { cc: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
        { cc: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
        { cc: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
        { cc: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
        { cc: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
        { cc: 'WST', symbol: 'WS$', name: 'Samoan tala' },
        { cc: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
        { cc: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
        { cc: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
        { cc: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
        { cc: 'XPF', symbol: 'F', name: 'CFP franc' },
        { cc: 'YER', symbol: 'YER', name: 'Yemeni rial' },
        { cc: 'ZAR', symbol: 'R', name: 'South African rand' },
        { cc: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
        { cc: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' }
      ]
    };
  }

  componentWillMount = async () => {
    const {
      history,
      location,
      match: {
        params: { id: hostId }
      }
    } = this.props;

    let hostTypeOptions = [];
    let partnerStatusOptions = [];
    let hostStatusOptions = [];
    let stateOptions = [];
    let countryOptions = [];

    getConstants('hostType').then(hostTypeRes => {
      if (hostTypeRes && hostTypeRes.status === 200) {
        hostTypeOptions = Object.keys(hostTypeRes.data).map(k => {
          return {
            label: hostTypeRes.data[k].label,
            value: hostTypeRes.data[k].code
          };
        });
      }
      this.setState({
        hostTypeOptions
      });
    });

    getConstants('partnerStatus').then(partnerStatusRes => {
      if (partnerStatusRes && partnerStatusRes.status === 200) {
        partnerStatusOptions = Object.keys(partnerStatusRes.data).map(k => {
          return {
            label: partnerStatusRes.data[k].label,
            value: partnerStatusRes.data[k].code
          };
        });
      }
      this.setState({
        partnerStatusOptions
      });
    });

    getConstants('hostStatus').then(hostStatusRes => {
      if (hostStatusRes && hostStatusRes.status === 200) {
        hostStatusOptions = Object.keys(hostStatusRes.data).map(k => {
          return {
            label: hostStatusRes.data[k].label,
            value: hostStatusRes.data[k].code
          };
        });
      }
      this.setState({
        hostStatusOptions
      });
    });

    getConstants('statesMY').then(statesRes => {
      if (statesRes && statesRes.status === 200) {
        stateOptions = Object.keys(statesRes.data).map(k => {
          return {
            key: statesRes.data[k].code,
            label: statesRes.data[k].label,
            value: statesRes.data[k].label
          };
        });
      }
      this.setState({
        statesSelection: stateOptions,
        hasFetchedStates: true
      });
    });

    getConstants('countries').then(countriesRes => {
      if (countriesRes && countriesRes.status === 200) {
        countryOptions = Object.keys(countriesRes.data)
          .map(k => ({
            key: countriesRes.data[k].iso2,
            label: countriesRes.data[k].name,
            value: countriesRes.data[k].name
          }))
          .filter(country => country.key === 'MY');
      }
      this.setState({
        countriesSelection: countryOptions,
        hasFetchedCountries: true
      });
    });

    if (hostId) {
      const stripeConnectAccountId = guard(() => queryString.parse(location.search).stripeConnectAccountId);

      if (!!stripeConnectAccountId) {
        await patchStripeConnectAccountId(hostId, stripeConnectAccountId)
          .then(host => {
            message.success('Stripe has successfully connected');
            history.replace({
              ...location,
              search: ''
            });
          })
          .catch(ex => {
            console.error(ex);
            message.error('Failed to connect to the Stripe, please contact our support team');
          });
      }

      getHostById(hostId)
        .then(res => {
          if (res && res.data && res.status === 200) {
            console.log('res.data', res.data);

            let description = res.data.description.filter(desc => desc.language === 'en')[0];
            this.props.form.setFieldsValue({
              name: res.data.name,
              email: res.data.email,
              invoiceEmail: res.data.invoiceEmail,
              contactNo: String(res.data.contactNo || ''),
              description: description ? description.text : '',
              taxNo: res.data.taxNo,
              timezone: res.data.timezone,
              currency: res.data.currency,
              signUpDate: moment(res.data.signUpDate),
              endDate: moment(res.data.endDate),
              hostType: res.data.hostType ? res.data.hostType : 0,
              partnerStatus: res.data.partnerStatus ? res.data.partnerStatus : 0,
              hostStatus: res.data.hostStatus,
              showAllOptionsFlag: res.data.showAllOptionsFlag,
              allowHostChargesInServicePackage: res.data.allowHostChargesInServicePackage || false,
              allowRentalListing: res.data.allowRentalListing || false,
              allowBookingEngine: res.data.allowBookingEngine || false,
              allowHotelBookingEngine: res.data.allowHotelBookingEngine || false,
              showStaySuitesInfo: res.data.showStaySuitesInfo || false,
              // useHostDetails: res.data.useHostDetails || false,
              children: res.data.children || [],
              bookingEngineDomain: res.data.bookingEngine && res.data.bookingEngine.domain,
              bookingEnginePrimaryColor: res.data.bookingEngine && res.data.bookingEngine.primaryColor,
              calendarMode: res.data.calendarMode || 'calendar'
            });
            this.setState({
              signUpDate: res.data.signUpDate,
              endDate: res.data.endDate,
              images: res.data.images,
              email: res.data.email,
              invoiceEmail: res.data.invoiceEmail,
              contactNo: res.data.contactNo,
              mode: 'edit',
              isLoading: false,
              address: res.data.street,
              city: res.data.city,
              zipcode: res.data.zipCode,
              state: res.data.state,
              country: res.data.countryCode,
              longitude: res.data.longitude,
              latitude: res.data.latitude,
              hostType: res.data.hostType ? res.data.hostType : 0,
              partnerStatus: res.data.partnerStatus ? res.data.partnerStatus : 0,
              children: res.data.children,
              allowHostChargesInServicePackage: res.data.allowHostChargesInServicePackage,
              allowRentalListing: res.data.allowRentalListing,
              allowBookingEngine: res.data.allowBookingEngine,
              allowHotelBookingEngine: res.data.allowHotelBookingEngine,
              showStaySuitesInfo: res.data.showStaySuitesInfo,
              bookingEngineDomain: res.data.bookingEngine && res.data.bookingEngine.domain,
              bookingEnginePrimaryColor: res.data.bookingEngine && res.data.bookingEngine.primaryColor,
              stripeConnectAccountId:
                res.data.bookingEngine && res.data.bookingEngine.paymentInfo && res.data.bookingEngine.paymentInfo.stripeConnectAccountId,
              stripeConnectAccountEmail:
                res.data.bookingEngine && res.data.bookingEngine.paymentInfo && res.data.bookingEngine.paymentInfo.stripeConnectAccountEmail,
              bookingEngineBackground: res.data.bookingEngine ? (res.data.bookingEngine.background ? res.data.bookingEngine.background : '') : ''
            });
          } else {
            message.error('There was an error fetching this host data');
            this.setState({ isLoading: false });
          }
        })
        .catch(ex => {
          console.log(ex);
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }

    getHostsWithoutParent(hostId)
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            isLoading: false,
            childrenList: res.data
          });
        } else {
          console.log('There was an error fetching host data');
          this.setState({ isLoading: false });
        }
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ isLoading: false });
      });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const state = this;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.signUpDate = values.signUpDate.format('YYYY-MM-DD');
        values.endDate = values.endDate.format('YYYY-MM-DD');
        values.hostType = this.state.hostType;
        values.partnerStatus = this.state.partnerStatus;
        values.bookingEngine =
          values.bookingEngineDomain || values.bookingEnginePrimaryColor
            ? {
                domain: values.bookingEngineDomain,
                primaryColor: values.bookingEnginePrimaryColor,
                background: this.state.bookingEngineBackground
              }
            : undefined;
        if (this.state.images && this.state.images.length > 0) {
          values.images = this.state.images;
        }
        values.description = values.description
          ? {
              language: 'en',
              text: values.description
            }
          : undefined;
        const history = this.props.history;
        if (this.state.mode === 'new') {
          state.setState({ isSaveButtonLoading: true });
          createHost(values)
            .then(res => {
              history.push('/host');
              message.success('Host created!');
            })
            .catch(ex => {
              state.setState({ isSaveButtonLoading: false });
              message.error('Something went wrong and host is not created.');
            });
        } else if (this.state.mode === 'edit') {
          const hostId = this.props.match.params.id;
          const isHostChargesChangedToDisallow = this.state.allowHostChargesInServicePackage && !values.allowHostChargesInServicePackage;
          const isHostRentalListingChangedToDisallow = this.state.allowRentalListing && !values.allowRentalListing;

          let title = '';
          let content = '';

          if (this.props.checkIsAdmin() && (isHostChargesChangedToDisallow || isHostRentalListingChangedToDisallow)) {
            let hostSettingsTitle = [];
            let hostSettingsContent = [];

            if (isHostChargesChangedToDisallow) {
              hostSettingsTitle.push('host charge');
              hostSettingsContent.push('service packages and payout calculations');
            }
            if (isHostRentalListingChangedToDisallow) {
              hostSettingsTitle.push('rental listing permission');
              hostSettingsContent.push('users');
            }

            title = (
              <span>
                <b>Warning!</b> You're changing the <b>{hostSettingsTitle.join(', ')}</b> setting. Are you sure you want to overwrite existing data?
              </span>
            );

            content = (
              <span>
                These items of this host will be affected: <br />
                <b>{hostSettingsContent.join('\n')}</b> <br />
                <br />
                You will not be able to undo this action, but you may update it again.
              </span>
            );
          }

          title = title || 'Are you sure you want to overwrite existing data?';
          content = content || 'You will not be able to undo this action, but you may update it again.';

          Modal.confirm({
            title,
            content,
            onOk() {
              state.setState({ isSaveButtonLoading: true });
              updateHost(hostId, { ...values, isHostChargesChangedToDisallow, isHostRentalListingChangedToDisallow })
                .then(res => {
                  console.log('updated values', values);
                  history.push('/host');
                  message.success('Host updated!');
                })
                .catch(ex => {
                  state.setState({ isSaveButtonLoading: false });
                  message.error('Something went wrong and host is not updated.');
                });
            },
            onCancel() {}
          });
        }
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const hostId = this.props.match.params.id;
    const history = this.props.history;
    const state = this;
    Modal.confirm({
      title: 'Are you sure want to delete this host?',
      content: 'This action cannot be reversed. Once deleted, it cannot be recovered.',
      onOk() {
        state.setState({ isDeleteButtonLoading: true });
        deleteHost(hostId)
          .then(res => {
            if (res.status === 204) {
              history.push('/host');
              message.success('Host deleted!');
            } else {
              state.setState({ isDeleteButtonLoading: false });
              message.error('Something went wrong and host is not deleted.');
            }
          })
          .catch(ex => {
            console.log(ex);
          });
      },
      onCancel() {}
    });
  };

  disabledStartDate = signUpDate => {
    const endDate = this.state.endDate;
    if (!signUpDate || !endDate) {
      return false;
    }
    return signUpDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = endDate => {
    const signUpDate = this.state.signUpDate;
    if (!endDate || !signUpDate) {
      return false;
    }
    return endDate.valueOf() <= signUpDate.valueOf();
  };

  onStartChange = value => {
    this.setState({ signUpDate: value });
  };

  onEndChange = value => {
    this.setState({ endDate: value });
  };

  changeHostType = hostType => {
    this.setState({
      hostType
    });
  };

  changePartnerStatus = partnerStatus => {
    this.setState({
      partnerStatus
    });
  };

  handleFinishedUpload = info => {
    this.setState({ images: [{ imageUrl: info.fileUrl }] });
  };

  handleFinishedUploadBackground = info => {
    this.setState({ bookingEngineBackground: info.fileUrl });
  };

  handleOnStripeConnectClick = async () => {
    const hostId = this.props.match.params.id;
    const { stripeAccountLinksUrl } = await getStripeConnectAccountLink(hostId);
    window.location.href = stripeAccountLinksUrl;
  };

  handleOnChangeBookingEngine = (isAllowBookingEngine, isAllowHotelBookingEngine) => {
    if (isAllowBookingEngine && isAllowHotelBookingEngine) {
      this.props.form.setFieldsValue({
        allowBookingEngine: true,
        allowHotelBookingEngine: false
      });
      this.setState({
        allowBookingEngine: true,
        allowHotelBookingEngine: false
      });
    }
  };

  handleOnChangeHotelBookingEngine = (isAllowHotelBookingEngine, isAllowBookingEngine) => {
    if (isAllowHotelBookingEngine && isAllowBookingEngine) {
      this.props.form.setFieldsValue({
        allowBookingEngine: false,
        allowHotelBookingEngine: true
      });
      this.setState({
        allowBookingEngine: false,
        allowHotelBookingEngine: true
      });
    }
  };

  render() {
    const { checkIsAdmin, checkIsAdminReadOnly, form } = this.props;
    const {
      images,
      hasFetchedCountries,
      statesSelection,
      countriesSelection,
      partnerStatusOptions,
      hostStatusOptions,
      isSaveButtonLoading,
      isDeleteButtonLoading,
      hasFetchedStates,
      isLoading,
      mode,
      hostTypeOptions,
      childrenList,
      children,
      bookingEngineDomain,
      bookingEnginePrimaryColor,
      stripeConnectAccountId,
      stripeConnectAccountEmail,
      address,
      city,
      zipcode,
      state,
      country,
      longitude,
      latitude,
      allowBookingEngine,
      allowHotelBookingEngine,
      bookingEngineBackground,
      gmtList,
      currencyList
    } = this.state;

    const { getFieldDecorator } = form;

    const formIsAllowBookingEngine = form.getFieldValue('allowBookingEngine');
    const formIsAllowHotelBookingEngine = form.getFieldValue('allowHotelBookingEngine');
    const isAllowBookingEngine = formIsAllowBookingEngine === undefined ? allowBookingEngine : formIsAllowBookingEngine;
    const isAllowHotelBookingEngine = formIsAllowHotelBookingEngine === undefined ? allowHotelBookingEngine : formIsAllowHotelBookingEngine;

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Card loading={isLoading}>
          <CloseButton
            onClick={() => {
              this.props.history.push('/host');
            }}
          />
          <Tabs defaultActiveKey="1">
            <TabPane tab="General Details" key="1">
              <AntdCard title="Basic Information" className="host-form-card" loading={isLoading}>
                <FormItem label="Host Name">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input a host name!' }]
                  })(<Input placeholder="eg. HostAStay" disabled={!checkIsAdmin() && mode && mode === 'edit'} />)}
                </FormItem>
                <Row type="flex" justify="left" gutter={36}>
                  <FormItem label="Email" className="host-form-list" style={{ flex: 1 }}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input host email!',
                          pattern: EMAIL_REGEX
                        }
                      ]
                    })(<Input placeholder="eg. email@hostastay.com" disabled={!checkIsAdmin() && mode && mode === 'edit'} />)}
                  </FormItem>
                  <FormItem label="Contact Number" className="host-form-list">
                    {getFieldDecorator('contactNo', {
                      rules: [{ required: true, message: 'Please input valid host contact number!', pattern: CONTACT_NUMBER_REGEX }]
                    })(<Input placeholder="eg. 0123456789" disabled={!checkIsAdmin() && this.state.mode && this.state.mode === 'edit'} />)}
                  </FormItem>
                </Row>
                <Row type="flex" justify="left" gutter={36}>
                  <FormItem label="Invoice Email" className="host-form-list">
                    {getFieldDecorator('invoiceEmail', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input a valid email',
                          pattern: EMAIL_REGEX
                        }
                      ]
                    })(<Input placeholder="eg. email@hostastay.com" disabled={!checkIsAdmin() && mode && mode === 'edit'} />)}
                  </FormItem>
                  {/* <FormItem
                    style={{ paddingLeft: 18 }}
                    label={<div className="wrap-text">{`Use Host details (Name & Address) for invoices and other documentations?`}</div>}
                  >
                    {getFieldDecorator('useHostDetails', { valuePropName: 'checked' })(<Switch />)}
                  </FormItem> */}
                </Row>
                <Row type="flex" justify="left" gutter={36}>
                  <FormItem label="Contract Start Date" className="host-form-list" style={{ flex: 1 }}>
                    {getFieldDecorator('signUpDate', {
                      rules: [{ required: true, message: 'Please input a sign up date!' }]
                    })(
                      <DatePicker
                        disabledDate={this.disabledStartDate}
                        format="YYYY-MM-DD"
                        placeholder="Contract Start Date"
                        onChange={this.onStartChange}
                        style={{ width: '100%' }}
                        disabled={!checkIsAdmin() && mode && mode === 'edit'}
                      />
                    )}
                  </FormItem>
                  <FormItem label="Contract End Date" className="host-form-list">
                    {getFieldDecorator('endDate', {
                      rules: [{ required: true, message: 'Please input an end date!' }]
                    })(
                      <DatePicker
                        disabledDate={this.disabledEndDate}
                        format="YYYY-MM-DD"
                        placeholder="Contract End Date"
                        onChange={this.onEndChange}
                        style={{ width: '100%' }}
                        disabled={!checkIsAdmin() && mode && mode === 'edit'}
                      />
                    )}
                  </FormItem>
                </Row>
                <Row type="flex" justify="left" gutter={36}>
                  <FormItem label="Host Type" className="host-form-list">
                    {getFieldDecorator('hostType', {
                      rules: [{ required: true, message: 'Please select your host type!' }]
                    })(
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select the Host Type"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.changeHostType}
                        disabled={!checkIsAdmin() && mode && mode === 'edit'}
                      >
                        {hostTypeOptions.map(hostTypeOption => {
                          return (
                            <Option key={hostTypeOption.value} value={hostTypeOption.value}>
                              {hostTypeOption.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem label="Partnership Status" className="host-form-list">
                    {getFieldDecorator('partnerStatus', {
                      rules: [{ required: true, message: 'Please select your partnership status!' }]
                    })(
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select the Partnership Status"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.changePartnerStatus}
                        disabled={!checkIsAdmin() && mode && mode === 'edit'}
                      >
                        {partnerStatusOptions.map(partnerStatusOption => {
                          return (
                            <Option key={partnerStatusOption.value} value={partnerStatusOption.value}>
                              {partnerStatusOption.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem label="Tax Registration Number" className="host-form-list">
                    {getFieldDecorator('taxNo')(<Input placeholder="eg. 10002917-IN" />)}
                  </FormItem>

                  <FormItem label="Description" className="host-form-list">
                    {getFieldDecorator('description')(<Input placeholder="eg. HostAStay is a Short Stay Management Company!" />)}
                  </FormItem>

                  <FormItem label="Host Status" className="host-form-list">
                    {getFieldDecorator('hostStatus', {
                      rules: [{ required: true, message: 'Please select host status!' }],
                      initialValue: 'live'
                    })(
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select the Host Status"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        // onChange={this.changePartnerStatus}
                        disabled={!checkIsAdmin() && mode && mode === 'edit'}
                      >
                        {hostStatusOptions.map(hostStatusOption => {
                          return (
                            <Option key={hostStatusOption.value} value={hostStatusOption.value}>
                              {hostStatusOption.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem label="Currency" className="host-form-list">
                    {getFieldDecorator('currency', {
                      rules: [{ required: true, message: 'Please select Currency' }],
                      initialValue: 'MYR'
                    })(
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select Currency"
                        disabled={!checkIsAdmin() && mode && mode === 'edit'}
                      >
                        {currencyList.map(currencyList => {
                          return (
                            <Option key={currencyList.cc} value={currencyList.cc}>
                              {currencyList.name} ({currencyList.cc})
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem label="Timezone" className="host-form-list">
                    {getFieldDecorator('timezone', {
                      rules: [{ required: true, message: 'Please select GMT' }],
                      initialValue: '(UTC+08:00) Kuala Lumpur, Singapore'
                    })(
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select Timezone"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        disabled={!checkIsAdmin() && mode && mode === 'edit'}
                      >
                        {gmtList.map(gmtList => {
                          return (
                            <Option key={gmtList.text} value={gmtList.text}>
                              {gmtList.text}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>

                  {/* Prevent Upload Logo Take Right Side */}
                  <div style={{ width: '100%', height: 0 }} />
                  {/* Prevent Upload Logo Take Right Side ^ */}

                  <Row className="host-form-list-upload-logo" type="flex" gutter={24}>
                    <FormItem label="Current Host Logo">
                      <Avatar shape="square" size={200} src={images.length > 0 ? images[0].imageUrl : ''} />
                    </FormItem>
                    <FormItem label="Upload Host Logo">
                      <DropzoneS3Uploader onFinish={this.handleFinishedUpload} s3Url={S3_URL} maxSize={1024 * 1024 * 5} upload={UPLOAD_OPTIONS} />
                    </FormItem>
                  </Row>
                </Row>
              </AntdCard>
              <div className="host-form-card">
                {statesSelection && countriesSelection && (
                  <LocationCard
                    form={form}
                    hasFetchedStates={hasFetchedStates}
                    hasFetchedCountries={hasFetchedCountries}
                    states={statesSelection}
                    countries={countriesSelection}
                    defaultValues={{ address, city, zipcode, state, country, longitude, latitude }}
                  />
                )}
              </div>
            </TabPane>
            {checkIsAdmin() && (
              <TabPane className="host-tab-pane" tab="Settings" key="Settings">
                <AntdCard title="Basic Information" className="host-form-card" loading={isLoading}>
                  <Row>
                    <FormItem label="Inventory availability formula?">
                      {getFieldDecorator('calendarMode', {
                        initialValue: 'calendar'
                      })(
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value="calendar">Based on Unit Empty Slot</Radio.Button>
                          <Radio.Button value="inventory">Based on Number of Reservation</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                    <FormItem label={<div className="wrap-text">Allow host to see 'All Property' option in Multicalendar?</div>}>
                      {getFieldDecorator('showAllOptionsFlag', { valuePropName: 'checked' })(
                        <Switch checkedChildren="Allow" unCheckedChildren="Disallow" />
                      )}
                    </FormItem>
                    <FormItem label={<div className="wrap-text">Allow host to add host charges in Service Package?</div>}>
                      {getFieldDecorator('allowHostChargesInServicePackage', { valuePropName: 'checked' })(
                        <Switch checkedChildren="Allow" unCheckedChildren="Disallow" />
                      )}
                    </FormItem>
                    <FormItem label={<div className="wrap-text">Allow host to access rental listing feature?</div>}>
                      {getFieldDecorator('allowRentalListing', { valuePropName: 'checked' })(
                        <Switch checkedChildren="Allow" unCheckedChildren="Disallow" />
                      )}
                    </FormItem>
                    <FormItem
                      label={
                        <div className="wrap-text">
                          Allow host to connect to Booking Website?{' '}
                          <Tooltip
                            title={
                              <span style={{ fontSize: '12px', margin: '4px' }}>{'Only 1 booking website settings can be selected at a time'}</span>
                            }
                          >
                            <Icon type="question-circle-o" style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </div>
                      }
                    >
                      {getFieldDecorator('allowBookingEngine', { valuePropName: 'checked' })(
                        <Switch checkedChildren="Allow" unCheckedChildren="Disallow" onChange={this.handleOnChangeBookingEngine} />
                      )}
                    </FormItem>
                    <FormItem
                      label={
                        <div className="wrap-text">
                          Allow host to connect to Hotel Booking Website?{' '}
                          <Tooltip title={<span style={{ fontSize: '12px' }}>{'Only 1 booking website settings can be selected at a time'}</span>}>
                            <Icon type="question-circle-o" style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </div>
                      }
                    >
                      {getFieldDecorator('allowHotelBookingEngine', { valuePropName: 'checked' })(
                        <Switch checkedChildren="Allow" unCheckedChildren="Disallow" onChange={this.handleOnChangeHotelBookingEngine} />
                      )}
                    </FormItem>
                    <FormItem label={<div className="wrap-text">Show StaySuites integration info such as rate in MC to Host?</div>}>
                      {getFieldDecorator('showStaySuitesInfo', { valuePropName: 'checked' })(
                        <Switch checkedChildren="Allow" unCheckedChildren="Disallow" />
                      )}
                    </FormItem>
                  </Row>
                </AntdCard>
              </TabPane>
            )}
            {checkIsAdmin() && (
              <TabPane className="host-tab-pane" tab="Multi Level Host Management" key="2" disabled={!checkIsAdmin() && mode && mode === 'edit'}>
                <HostRelationshipCard className="host-form-card" loading={isLoading} form={form} childrenList={childrenList} children={children} />
              </TabPane>
            )}
            {(isAllowBookingEngine || isAllowHotelBookingEngine) && (
              <TabPane className="host-tab-pane" tab="Booking Website Configuration" key="bookingEngineConfig" forceRender>
                <HostBookingEngineConfigCard
                  loading={isLoading}
                  form={form}
                  mode={mode}
                  defaultValues={{
                    bookingEngineDomain,
                    bookingEnginePrimaryColor,
                    stripeConnectAccountId,
                    stripeConnectAccountEmail,
                    bookingEngineBackground
                  }}
                  onStripeConnectClick={this.handleOnStripeConnectClick}
                  S3_URL={S3_URL}
                  UPLOAD_OPTIONS={UPLOAD_OPTIONS}
                  handleFinishedUploadBackground={this.handleFinishedUploadBackground}
                  checkIsAdminReadOnly={checkIsAdminReadOnly}
                />
              </TabPane>
            )}
          </Tabs>

          <FormItem className="host-button-wrapper">
            {mode === 'new' ? (
              <React.Fragment>
                <Button
                  id="create-cfm-button4a-host"
                  type="primary"
                  htmlType="submit"
                  loading={isSaveButtonLoading}
                  disabled={checkIsAdminReadOnly()}
                >
                  Create
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button id="save-button6-edithost" type="primary" htmlType="submit" loading={isSaveButtonLoading} disabled={checkIsAdminReadOnly()}>
                  Save
                </Button>
                {checkIsAdmin() && (
                  <Button
                    id="del-button5-edithost"
                    type="danger"
                    onClick={this.handleDelete}
                    className="sp-button-margin"
                    loading={isDeleteButtonLoading}
                    disabled={checkIsAdminReadOnly()}
                  >
                    Delete
                  </Button>
                )}
              </React.Fragment>
            )}
          </FormItem>
        </Card>
      </Form>
    );
  }
}

HostForm.propTypes = {
  checkIsAdmin: PropTypes.func
};

HostForm.defaultProps = {
  checkIsAdmin: () => {}
};

export default withRouter(Form.create()(withAppContext(HostForm)));
