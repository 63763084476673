import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Form, Row, Col, Alert, List, Divider, Tooltip, Icon, Switch, Card, Tag, Rate } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import {
  SEPARATOR_DASH,
  STANDARD_RATE_CODE,
  RATE_DISTRIBUTION_TAB,
  RATE_DERIVATIVE,
  RATE_DERIVATIVES,
  AIRBNB_GUEST_REVIEW_CATEGORY
} from 'utils/constants';
import { getConstantsNew } from 'utils/apis/constants';
import { getAirbnbAdvanceOptions } from 'utils/apis/integration';
import { getWebRateByRoomType } from 'utils/apis/rate';
import { guard } from 'utils/general';

import FormInput, { FormInputReadOnly } from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection, { FormMultiSelectionReadOnly } from 'components/FormSelection/FormSelection';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import FormTimePicker from 'components/FormTimePicker/FormTimePicker';

import WrappedTaxProvided from 'components/DynamicFieldSet/TaxFieldSet';
import AdvancedOptionsModal from '../../../../AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';
import './AirbnbAdvanceOptionModal.css';
import { getCurrencyExchangeRate } from 'utils/apis/currencyExchange';

const TabPane = Tabs.TabPane;

const AIRBNB_RESORT_FEE_KEY = 'PASS_THROUGH_RESORT_FEE';
const AIRBNB_MANAGEMENT_FEE_KEY = 'PASS_THROUGH_MANAGEMENT_FEE';
const AIRBNB_COMMUNITY_FEE_KEY = 'PASS_THROUGH_COMMUNITY_FEE';
const AIRBNB_LINEN_FEE_KEY = 'PASS_THROUGH_LINEN_FEE';
const AIRBNB_CLEANING_FEE = 'PASS_THROUGH_CLEANING_FEE';
const AIRBNB_SHORT_STAY_CLEANING_FEE = 'PASS_THROUGH_SHORT_TERM_CLEANING_FEE';

const LinkToBooking = ({ resId, resCode }) => (
  <a target="_blank" href={`/reservation/${resId}/?selectedTab=reviews`} rel="noopener noreferrer">
    &nbsp; <Icon type="link" /> {`${resCode}`}
  </a>
);

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const rateId = splittedFieldKeys[3];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => room.roomId === roomId);

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => rateObject.rate._id === rateId || rateObject.otaId === otaId);

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: { _id: rateId } };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }

  return payload;
};

const getFilteredSubTypeSelections = (selectedParentValue, selectionsToFilter, parentSelections) => {
  let filteredKeys;
  if (selectedParentValue) {
    filteredKeys = Object.keys(selectionsToFilter).filter(key => parentSelections[selectedParentValue].allowSubTypes.includes(key));
  } else {
    filteredKeys = Object.keys(selectionsToFilter);
  }

  return filteredKeys.map(filteredKey => ({
    key: selectionsToFilter[filteredKey].code,
    value: selectionsToFilter[filteredKey].code,
    displayValue: selectionsToFilter[filteredKey].label
  }));
};

const splitUnderscoreAndCapitalize = unformattedString => {
  return unformattedString
    .split('_')
    .map(splittedKey => splittedKey.charAt(0).toUpperCase() + splittedKey.slice(1))
    .join(' ');
};

const mapConstantsToSelections = constants =>
  Object.values(constants).map(eachConstant => ({
    key: eachConstant.code,
    value: eachConstant.code,
    displayValue: eachConstant.label
  }));

const getRealFieldValue = (form, defaultValues, key) => {
  return form.getFieldValue(key) !== undefined ? form.getFieldValue(key) : defaultValues[key];
};
const DetailsForm = ({ form, defaultValues, propertyTypeGroups, propertyTypes, roomTypes, airbnbCheckinOptions }) => {
  const propertyTypeGroupSelections = mapConstantsToSelections(propertyTypeGroups);
  const airbnbCheckinOptionsSelections = mapConstantsToSelections(airbnbCheckinOptions);
  const propertyTypesSelections = getFilteredSubTypeSelections(form.getFieldValue('propertyTypeGroup'), propertyTypes, propertyTypeGroups);
  const roomTypesSelections = getFilteredSubTypeSelections(form.getFieldValue('propertyTypeCategory'), roomTypes, propertyTypes);
  return (
    <div className="scrollBarStyle">
      <h3>Title & Description</h3>
      <FormInput
        form={form}
        formLabel="Title"
        name="title"
        className="fullLengthTextBox"
        defaultValue={defaultValues.title}
        requiredErrorMessage="This is required"
        extraRules={[
          {
            min: 8,
            message: 'Title need to be at least 8 characters'
          }
        ]}
      />
      <FormInputReadOnly className="fullLengthTextBox" label="Summary" text={defaultValues.summary} />
      <FormInputReadOnly className="fullLengthTextBox" label="The Space" text={defaultValues.space} />
      <FormInputReadOnly className="fullLengthTextBox" label="Guest Access" text={defaultValues.access} />
      <FormInputReadOnly className="fullLengthTextBox" label="Guest Interaction" text={defaultValues.interaction || ''} />
      <FormInputReadOnly className="fullLengthTextBox" label="Other Things to Note" text={defaultValues.notes || ''} />
      <FormInputReadOnly className="fullLengthTextBox" label="The Neighborhood" text={defaultValues.neighborhood} />

      <h3>Room & Guests</h3>
      <FormInputReadOnly className="fullLengthTextBox" label="Bathroom" text={defaultValues.numOfBathroom} />
      <p>Bedrooms </p>
      <List itemLayout="horizontal" locale={{ emptyText: 'No bedroom added' }}>
        {defaultValues.rooms.map((room, index) => {
          const description = room.beds.reduce((roomBedsDescription, bed, i) => {
            const stringBefore = i === 0 ? `${roomBedsDescription}` : `${roomBedsDescription},`;
            return `${stringBefore} ${bed.quantity} ${splitUnderscoreAndCapitalize(bed.type)}`;
          }, '');
          return (
            <List.Item key={index}>
              <List.Item.Meta title={`Room ${room.room_number}`} description={description} />
            </List.Item>
          );
        })}
      </List>
      <FormSelection
        form={form}
        formLabel="Which is most like your place?"
        name="propertyTypeGroup"
        className="fullLengthTextBox"
        defaultValue={defaultValues.propertyTypeGroup}
        selections={propertyTypeGroupSelections}
        onChange={() => {
          form.setFieldsValue({ propertyTypeCategory: '', roomTypeCategory: '' });
        }}
      />
      {getRealFieldValue(form, defaultValues, 'propertyTypeGroup') && (
        <FormSelection
          form={form}
          formLabel="Property Type"
          name="propertyTypeCategory"
          className="fullLengthTextBox"
          defaultValue={defaultValues.propertyTypeCategory}
          selections={propertyTypesSelections}
          onChange={() => {
            form.setFieldsValue({ roomTypeCategory: '' });
          }}
        />
      )}
      {getRealFieldValue(form, defaultValues, 'propertyTypeCategory') && (
        <FormSelection
          form={form}
          formLabel="Listing Type"
          name="roomTypeCategory"
          className="fullLengthTextBox"
          defaultValue={defaultValues.roomTypeCategory}
          selections={roomTypesSelections}
        />
      )}

      <h3>Amenities</h3>
      <FormMultiSelectionReadOnly className="fullLengthTextBox" label="Amenities" selections={defaultValues.amenities} size="large" />

      <h3>Location</h3>
      <FormInputReadOnly className="fullLengthTextBox" label="Street Address" text={defaultValues.street} />
      <FormInputReadOnly className="fullLengthTextBox" label="City" text={defaultValues.city} />
      <FormInputReadOnly className="fullLengthTextBox" label="ZIP Code" text={defaultValues.zipcode} />
      <FormInputReadOnly className="fullLengthTextBox" label="State" text={defaultValues.state} />
      <FormInputReadOnly className="fullLengthTextBox" label="Country" text={defaultValues.country} />

      <h3>Guest Resource</h3>
      <FormSelection
        form={form}
        formLabel="Select a Check-in Method"
        name="checkinOption"
        className="fullLengthTextBox"
        defaultValue={defaultValues.checkinOption}
        selections={airbnbCheckinOptionsSelections}
      />
      {!!getRealFieldValue(form, defaultValues, 'checkinOption') && (
        <FormInput
          form={form}
          formLabel="Check-in Instructions"
          name="checkinInstruction"
          className="fullLengthTextBox"
          defaultValue={defaultValues.checkinInstruction}
          placeholder="Instructions for guest on how to check in."
          extraRules={[{ required: !!getRealFieldValue(form, defaultValues, 'checkinOption'), message: 'This is required' }]}
        />
      )}
    </div>
  );
};

const AIRBNB_RATE_TYPE_FLAT_KEY = 'flat';
const AIRBNB_RATE_TYPE_PERCENT_KEY = 'percent';
const AIRBNB_FEE_MULTIPLIER = 1000000;
const calcCorrectFee = (feeType, fee, isDisplay = false) => {
  if (feeType === AIRBNB_RATE_TYPE_FLAT_KEY) {
    if (isDisplay) {
      return fee / AIRBNB_FEE_MULTIPLIER;
    } else {
      return fee * AIRBNB_FEE_MULTIPLIER;
    }
  }
  return fee;
};
const calcCorrectDiscountFactor = (discountPercentage = 0, isDisplay = false) => {
  if (discountPercentage !== undefined && discountPercentage !== null) {
    if (isDisplay) {
      return 100 - Number(discountPercentage) * 100;
    } else {
      return (100 - Number(discountPercentage)) / 100;
    }
  }
  return undefined;
};
const getFeeType = (feeName, form, defaultValues) => {
  return form.getFieldValue(`${feeName}Type`) || defaultValues[feeName].amount_type;
};
const checkFeeTypeIsPercentage = (feeName, form, defaultValues) => {
  return getFeeType(feeName, form, defaultValues) === AIRBNB_RATE_TYPE_PERCENT_KEY;
};
const FeeTypeSelection = ({ form, name, defaultValue, isFlatOnly = false, currency = 'RM' }) => {
  const selections = [{ key: AIRBNB_RATE_TYPE_FLAT_KEY, value: AIRBNB_RATE_TYPE_FLAT_KEY, displayValue: `Flat rate (${currency})` }];
  if (!isFlatOnly) {
    selections.push({ key: AIRBNB_RATE_TYPE_PERCENT_KEY, value: AIRBNB_RATE_TYPE_PERCENT_KEY, displayValue: 'Percentage (%)' });
  }
  return <FormSelection form={form} formLabel="Fee Type" name={name} defaultValue={defaultValue} selections={selections} allowClear={false} />;
};
const PricingForm = ({ form, defaultValues, currency = 'RM', minExtraGuestFee = 24 }) => {
  const validateFee = (minValue, maxValue) => (rule, value, callback) => {
    if (value !== null && value !== undefined && Number(value) > 0) {
      if (value < minValue) {
        callback(new Error(`Fees must be at least ${currency} ${minValue} but no more than ${currency} ${maxValue}`));
      }
      if (value > maxValue) {
        callback(new Error(`Fees must be at least ${currency} ${minValue} but no more than ${currency} ${maxValue}`));
      }
    }
    callback();
  };
  return (
    <div className="scrollBarStyle">
      <Row>
        <h3>Extra Fees</h3>
        <Row gutter={16}>
          <Col span={12}>
            <FormInputNumber
              form={form}
              formLabel={`For each guest after (Max ${defaultValues.maxCapacity} pax)`}
              name="guestsIncluded"
              defaultValue={defaultValues.guestsIncluded || defaultValues.maxCapacity}
              minValue={1}
              maxValue={defaultValues.maxCapacity}
            />
          </Col>
          <Col span={12} className="halfLengthTextBox">
            <FormInputNumber
              form={form}
              formLabel={`Extra Guest Fee (${currency})`}
              name="extraGuestFee"
              defaultValue={defaultValues.extraGuestFee}
              minValue={0}
              precision={2}
              extraRules={[{ validator: validateFee(minExtraGuestFee, 999999999) }]}
            />
          </Col>
        </Row>
        {/* <Col span={24} className="fullLengthTextBox">
          <FormInputNumber
            form={form}
            formLabel="Cleaning Fee (RM)"
            name="cleaningFee"
            defaultValue={defaultValues.cleaningFee}
            precision={2}
            minValue={0}
            extraRules={[{ validator: validateFee(21, 2501) }]}
          />
        </Col> */}
      </Row>
      <Row>
        <h3>Length of Stay Discount</h3>
        <Row gutter={16}>
          <Col span={12}>
            <FormInputNumber
              form={form}
              formLabel="Weekly Discount (%)"
              name="weeklyDiscount"
              defaultValue={defaultValues.weeklyDiscount}
              minValue={0}
              maxValue={99}
              precision={2}
              extraRules={[
                {
                  validator: (rule, value, callback) => {
                    form.validateFields(['monthlyDiscount'], { force: true });
                    callback();
                  }
                }
              ]}
              placeholder="Guest will get discount if they stay more than 7 days "
            />
          </Col>
          <Col span={12} className="halfLengthTextBox">
            <FormInputNumber
              form={form}
              formLabel="Monthly Discount (%)"
              name="monthlyDiscount"
              defaultValue={defaultValues.monthlyDiscount}
              minValue={0}
              maxValue={99}
              precision={2}
              extraRules={[
                {
                  validator: (rule, value, callback) => {
                    const weeklyDiscount = getRealFieldValue(form, defaultValues, 'weeklyDiscount');
                    if (Number(weeklyDiscount) > 0 && Number(value) > 0 && Number(weeklyDiscount) > Number(value)) {
                      return callback(new Error('Monthly discount must be more than weekly discount'));
                    }
                    return callback();
                  }
                }
              ]}
              placeholder="Guest will get discount if they stay more than a month"
            />
          </Col>
        </Row>
      </Row>
      {/* <Row>
        <h3>Security Deposit</h3>
        <Col span={24} className="fullLengthTextBox">
          <FormInputNumber
            form={form}
            formLabel="Security Deposit (RM)"
            name="securityDeposit"
            defaultValue={defaultValues.securityDeposit}
            precision={2}
            minValue={0}
            extraRules={[{ validator: validateFee(411, 20532) }]}
          />
        </Col>
      </Row> */}
      <Row>
        <h3>Other Fees</h3>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={10}>
                <FeeTypeSelection
                  form={form}
                  name="stdCleaningFeeType"
                  defaultValue={defaultValues.stdCleaningFee.amount_type}
                  isFlatOnly
                  currency={currency}
                />
              </Col>
              <Col span={14}>
                <FormInputNumber
                  form={form}
                  formLabel="Cleaning Fee"
                  name="stdCleaningFee"
                  // defaultValue={defaultValues.stdCleaningFee.amount || defaultValues.cleaningFee}
                  defaultValue={defaultValues.cleaningFee ? defaultValues.cleaningFee : defaultValues.stdCleaningFee.amount}
                  precision={2}
                  minValue={0}
                  maxValue={checkFeeTypeIsPercentage('stdCleaningFee', form, defaultValues) ? 100 : undefined}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={10}>
                <FeeTypeSelection
                  form={form}
                  name="shortStayCleaningFeeType"
                  defaultValue={defaultValues.shortStayCleaningFee.amount_type}
                  isFlatOnly
                  currency={currency}
                />
              </Col>
              <Col span={14}>
                <FormInputNumber
                  form={form}
                  formLabel={
                    <span>
                      Short-stay Cleaning Fee{' '}
                      <Tooltip title="Attract guests booking 1 or 2 nights by setting a lower cleaning fee.">
                        <Icon type="question-circle" />
                      </Tooltip>
                    </span>
                  }
                  name="shortStayCleaningFee"
                  defaultValue={defaultValues.shortStayCleaningFee.amount}
                  precision={2}
                  minValue={0}
                  maxValue={
                    checkFeeTypeIsPercentage('shortStayCleaningFee', form, defaultValues)
                      ? 100
                      : form.getFieldValue('stdCleaningFee') > 0
                      ? (form.getFieldValue('stdCleaningFee') || defaultValues.stdCleaningFee.amount || defaultValues.cleaningFee) - 1
                      : form.getFieldValue('stdCleaningFee') === 0 || defaultValues.stdCleaningFee.amount === 0 || defaultValues.cleaningFee === 0
                      ? 0
                      : (form.getFieldValue('stdCleaningFee') || defaultValues.stdCleaningFee.amount || defaultValues.cleaningFee) - 1
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={10}>
                <FeeTypeSelection form={form} name="resortFeeType" defaultValue={defaultValues.resortFee.amount_type} currency={currency} />
              </Col>
              <Col span={14}>
                <FormInputNumber
                  form={form}
                  formLabel="Resort Fee"
                  name="resortFee"
                  defaultValue={defaultValues.resortFee.amount}
                  precision={2}
                  minValue={0}
                  maxValue={checkFeeTypeIsPercentage('resortFee', form, defaultValues) ? 100 : undefined}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={10}>
                <FeeTypeSelection form={form} name="managementFeeType" defaultValue={defaultValues.managementFee.amount_type} currency={currency} />
              </Col>
              <Col span={14} className="quarterLengthTextBox">
                <FormInputNumber
                  form={form}
                  formLabel="Management Fee"
                  name="managementFee"
                  defaultValue={defaultValues.managementFee.amount}
                  precision={2}
                  minValue={0}
                  maxValue={checkFeeTypeIsPercentage('managementFee', form, defaultValues) ? 100 : undefined}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={10}>
                <FeeTypeSelection form={form} name="communityFeeType" defaultValue={defaultValues.communityFee.amount_type} currency={currency} />
              </Col>
              <Col span={14}>
                <FormInputNumber
                  form={form}
                  formLabel="Community Fee"
                  name="communityFee"
                  defaultValue={defaultValues.communityFee.amount}
                  precision={2}
                  minValue={0}
                  maxValue={checkFeeTypeIsPercentage('communityFee', form, defaultValues) ? 100 : undefined}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={10}>
                <FeeTypeSelection form={form} name="linenFeeType" defaultValue={defaultValues.linenFee.amount_type} isFlatOnly currency={currency} />
              </Col>
              <Col span={14} className="quarterLengthTextBox">
                <FormInputNumber
                  form={form}
                  formLabel="Linens Fee"
                  name="linenFee"
                  defaultValue={defaultValues.linenFee.amount}
                  precision={2}
                  minValue={0}
                  maxValue={checkFeeTypeIsPercentage('linenFee', form, defaultValues) ? 100 : undefined}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

const POLICY_FLEXIBLE_VALUE = 'flexible';
const POLICY_MODERATE_VALUE = 'moderate';
const POLICY_STRICT_VALUE = 'strict_14_with_grace_period';

const getCancellationPolicyDescription = selectedPolicy => {
  switch (selectedPolicy) {
    case POLICY_FLEXIBLE_VALUE:
      return 'Full refund 1 day prior to arrival';
    case POLICY_MODERATE_VALUE:
      return 'Full refund 5 days prior to arrival';
    case POLICY_STRICT_VALUE:
      return 'Full refund for cancellations made within 48 hours of booking, if the check-in date is at least 14 days away. 50% refund for cancellations made at least 7 days before check-in. No refunds for cancellations made within 7 days of check-in.';
    default:
      return '';
  }
};

const AvailabilityForm = ({ form, defaultValues, toggle, toggleCustomValue }) => {
  const daysInWeekSelections = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' }
  ];
  return (
    <div className="scrollBarStyle">
      <h3>Reservation Preferences</h3>
      <FormRadioButton
        form={form}
        formLabel="Advance Notice"
        name="advanceNotice"
        className="advNoticeRadioBtn"
        buttonStyle="solid"
        defaultValue={defaultValues.advanceNoticeInHours}
        onChange={e => {
          toggle(e);
        }}
        selections={[
          { key: '0', value: 0, displayValue: 'Instant booking' },
          { key: '24', value: 24, displayValue: 'At least 1 day notice' },
          { key: '48', value: 48, displayValue: 'At least 2 days notice' },
          { key: '72', value: 72, displayValue: 'At least 3 days notice' },
          { key: '168', value: 168, displayValue: 'At least 7 days notice' }
        ]}
      />
      {toggleCustomValue && (
        <>
          <Tooltip title="Example: Selecting 22:00 will mean that guest can book up until 10pm on the same day. While the default 00:00 will allow guest to book anytime.">
            <span style={{ paddingBottom: '10px' }} className="wrap-text">
              {'Select Same Day Advance Notice. (Cut off time) '}
              <Icon type="question-circle-o" />
            </span>
          </Tooltip>
          <div className="shortLengthTextBox">
            <FormTimePicker
              form={form}
              fieldName="customAdvanceNotice"
              format="HH:00"
              defaultValue={defaultValues.advanceNoticeInHours < 24 ? (24 - defaultValues.advanceNoticeInHours).toString() + ':00' : '00:00'}
            />
          </div>
        </>
      )}
      <FormSelection
        form={form}
        formLabel="Preparation Time"
        name="preparationTime"
        className="fullLengthTextBox"
        defaultValue={defaultValues.preparationTime}
        selections={[
          { key: 0, value: 0, displayValue: 'None' },
          { key: 1, value: 1, displayValue: 'Block 1 night before and after each reservation' },
          { key: 2, value: 2, displayValue: 'Block 2 night before and after each reservation' }
        ]}
      />

      <h3>Trip Length</h3>
      <FormInputNumber
        form={form}
        formLabel="Min Night"
        name="minNight"
        className="fullLengthTextBox"
        defaultValue={defaultValues.minNight}
        minValue={0}
      />
      <span>Customize minimum stay requirements by day of week</span>
      <Row gutter={16}>
        <Col xs={20} sm={20} md={3} lg={3}>
          <FormInputNumber
            form={form}
            formLabel="Mon only"
            name="minNightMon"
            defaultValue={defaultValues.minNightMon}
            minValue={form.getFieldValue('minNight')}
          />
        </Col>
        <Col xs={20} sm={20} md={3} lg={3}>
          <FormInputNumber
            form={form}
            formLabel="Tue only"
            name="minNightTue"
            defaultValue={defaultValues.minNightTue}
            minValue={form.getFieldValue('minNight')}
          />
        </Col>
        <Col xs={20} sm={20} md={3} lg={3}>
          <FormInputNumber
            form={form}
            formLabel="Wed only"
            name="minNightWed"
            defaultValue={defaultValues.minNightWed}
            minValue={form.getFieldValue('minNight')}
          />
        </Col>
        <Col xs={20} sm={20} md={3} lg={3}>
          <FormInputNumber
            form={form}
            formLabel="Thu only"
            name="minNightThu"
            defaultValue={defaultValues.minNightThu}
            minValue={form.getFieldValue('minNight')}
          />
        </Col>
        <Col xs={20} sm={20} md={3} lg={3}>
          <FormInputNumber
            form={form}
            formLabel="Fri only"
            name="minNightFri"
            defaultValue={defaultValues.minNightFri}
            minValue={form.getFieldValue('minNight')}
          />
        </Col>
        <Col xs={20} sm={20} md={3} lg={3}>
          <FormInputNumber
            form={form}
            formLabel="Sat only"
            name="minNightSat"
            defaultValue={defaultValues.minNightSat}
            minValue={form.getFieldValue('minNight')}
          />
        </Col>
        <Col xs={20} sm={20} md={3} lg={3}>
          <FormInputNumber
            form={form}
            formLabel="Sun only"
            name="minNightSun"
            defaultValue={defaultValues.minNightSun}
            minValue={form.getFieldValue('minNight')}
          />
        </Col>
      </Row>
      <FormInputNumber
        form={form}
        formLabel={
          <Tooltip title="The maximum nights allowed is 1125.">
            Max Night <Icon type="question-circle-o" />
          </Tooltip>
        }
        name="maxNight"
        className="fullLengthTextBox"
        defaultValue={defaultValues.maxNight}
        minValue={0}
        maxValue={1125}
      />

      <h3>Check-in and Check-out</h3>
      <FormCheckbox
        form={form}
        formLabel="Check-in Allowed (Days)"
        name="checkInDaysAllowed"
        defaultValue={defaultValues.checkInDaysAllowed} // Read default value from API else default to this
        selections={daysInWeekSelections}
      />

      <FormCheckbox
        form={form}
        formLabel="Check-out Allowed (Days)"
        name="checkOutDaysAllowed"
        defaultValue={defaultValues.checkOutDaysAllowed} // Read default value from API else default to this
        selections={daysInWeekSelections}
      />
    </div>
  );
};

const OpportunitiesTab = ({ form, defaultValues, onUpdate }) => {
  return (
    <div className="scrollBarStyle">
      <h3>
        <Tooltip title="Opportunities are recommendations based on what we know guests are searching for on Airbnb, and enabling them could potentially improve booking performance. ">
          {'Opportunities '}
          <Icon type="question-circle-o" />
        </Tooltip>
      </h3>

      {defaultValues.map(opportunity => {
        const id = opportunity.id;
        const title = opportunity.title;
        const description = opportunity.description;
        const inputFields = opportunity.input_fields;
        const type = opportunity.type;
        return (
          <Card
            key={opportunity.id + opportunity.title}
            style={{ marginBottom: 16 }}
            size="small"
            title={
              inputFields.length > 1 ? (
                <Tooltip title="Please key in all relevant details in you wish to include this opportunity. ">
                  {`${title} `}
                  <Icon type="question-circle-o" />
                </Tooltip>
              ) : (
                title
              )
            }
            extra={inputFields.length === 0 && <Switch id={id} type="primary" onChange={e => onUpdate(false, type, '', e)} />}
          >
            <List.Item key={opportunity.id}>
              <List.Item.Meta description={description} />
            </List.Item>
            {inputFields.map((inputField, i) => {
              const inputFieldKey = inputField.key;
              const inputFieldLabel = inputField.key_label || '';
              const extraInfo =
                inputFieldLabel.toString() === 'Show discount'
                  ? 'Months Before Arrival'
                  : inputFieldLabel.toString() === 'When should the discount show up?'
                  ? 'Day(s) before check in'
                  : inputFieldLabel.toString() === 'Monthly discount'
                  ? 'Guest will get discount if they stay more than a month'
                  : inputFieldLabel.toString() === 'Weekly discount'
                  ? 'Guest will get discount if they stay more than a week'
                  : ' %';
              const valueConstraint = inputField.value_constraint;

              if (valueConstraint.value_type === 'INTEGER') {
                return (
                  <>
                    <FormInputNumber
                      form={form}
                      name={inputFieldKey}
                      label={`${inputFieldLabel} (${extraInfo})`}
                      className="quarterLengthTextBox"
                      placeholder={extraInfo}
                      // defaultValue={Number(valueConstraint.min_value)}
                      minValue={Number(valueConstraint.min_value)}
                      maxValue={Number(valueConstraint.max_value)}
                      // requiredErrorMessage={i === 1 ? 'This is required' : ''}
                      onChange={e => onUpdate(true, type, inputFieldKey, e, valueConstraint.value_type)}
                    ></FormInputNumber>
                  </>
                );
              } else if (valueConstraint.value_type === 'PERCENTAGE') {
                return (
                  <>
                    <FormInputNumber
                      form={form}
                      name={inputFieldKey}
                      label={`${inputFieldLabel} %`}
                      className="quarterLengthTextBox"
                      placeholder={extraInfo}
                      minValue={Number(valueConstraint.min_value)}
                      maxValue={Number(valueConstraint.max_value)}
                      onChange={e => onUpdate(true, type, inputFieldKey, e, valueConstraint.value_type)}
                    ></FormInputNumber>
                    {/* <Button id={id} type="primary" onClick={() => onUpdate(true, type, inputFieldKey)}>
                        Enable
                      </Button> */}
                  </>
                );
              } else if (valueConstraint.value_type === 'OPTION') {
                const options = valueConstraint.options;
                return (
                  <>
                    <FormRadioButton
                      form={form}
                      name={inputFieldKey}
                      buttonStyle="solid"
                      selections={[
                        { key: '0', value: options[0], displayValue: options[0] },
                        { key: '1', value: options[1], displayValue: options[1] },
                        { key: '2', value: options[2], displayValue: options[2] },
                        { key: '3', value: options[3], displayValue: options[3] }
                      ]}
                      onChange={e => onUpdate(true, type, inputFieldKey, e, valueConstraint.value_type)}
                    />
                  </>
                );
              }
              return <></>;
            })}
          </Card>
        );
      })}
    </div>
  );
};

const CheckoutTasksTab = ({ form, defaultValues, onToggleEnable }) => {
  return (
    <div className="scrollBarStyle">
      <h3>
        <Tooltip title="Settings for a list of specific tasks for guests to do before they checkout of the listing.">
          {'Check Out Tasks '}
          <Icon type="question-circle-o" />
        </Tooltip>
      </h3>

      {Object.keys(defaultValues).map(task => {
        const code = defaultValues[task].code;
        const title = defaultValues[task].title;
        const label = defaultValues[task].label;
        const details = defaultValues[task].task_detail;
        const present = defaultValues[task].is_present;
        return (
          <Card
            key={code}
            style={{ marginBottom: 16 }}
            size="small"
            title={title}
            extra={<Switch id={code} type="primary" onChange={e => onToggleEnable(e, task)} checked={present} />}
          >
            <List.Item key={title}>
              <List.Item.Meta description={label} />
            </List.Item>
            <FormInput
              form={form}
              name={task}
              placeholder="Describe any steps to be followed specifically. "
              defaultValue={details}
              disabled={!present}
              extraRules={[
                {
                  max: 140,
                  message: 'No more than 140 characters'
                }
              ]}
            />
          </Card>
        );
      })}
    </div>
  );
};

const ResIssuesTab = ({ form, defaultValues }) => {
  return (
    <div className="scrollBarStyle">
      <h3>
        <Tooltip title="Recent trip issues allow you to monitor a log of recent trips that have quality issues and take the appropriate actions to improve quality">
          {'Recent Trip Issues '}
          <Icon type="question-circle-o" />
        </Tooltip>
      </h3>

      {defaultValues.map(reservationIssue => {
        const id = reservationIssue.review_issues.review_id;
        const confirmationCode = reservationIssue.confirmation_code;
        const resId = reservationIssue.res_id;
        const overallRating = reservationIssue.review_issues.low_overall_rating;
        // const csViolation = reservationIssue.cs_violations || [];
        const lowCategoryRatings = reservationIssue.review_issues.low_category_ratings || [];
        const negativeCategoryTags = reservationIssue.review_issues.negative_category_tags || [];
        // const cancellation = reservationIssue.review_issues.cancellation || '';
        return (
          <Card
            key={id}
            // style={{ marginBottom: 16 }}
            size="small"
            title={`${overallRating}-star overall rating for reservation code: ${confirmationCode}`}
            extra={
              <div>
                <LinkToBooking resId={resId} resCode={confirmationCode} />
              </div>
            }
          >
            {lowCategoryRatings && negativeCategoryTags && (
              <Row justify="start">
                {lowCategoryRatings.map(lowCategoryRating => {
                  return (
                    <Row type="flex" style={{ color: 'grey', marginBottom: 16 }}>
                      <p className="fullLengthTextBox">
                        {`${lowCategoryRating.category} - `}
                        <Rate value={lowCategoryRating.rating} disabled={true} />
                      </p>
                    </Row>
                  );
                })}
                <Row gutter={8}>
                  {negativeCategoryTags.map(negativeCategoryTag => {
                    const airbnbReviewDetails = AIRBNB_GUEST_REVIEW_CATEGORY.filter(
                      review => review.tag.toUpperCase() === negativeCategoryTag.tag.toUpperCase()
                    );

                    return <Tag color="orange">{airbnbReviewDetails[0].description}</Tag>;
                  })}
                </Row>
              </Row>

              // </List.Item>
            )}
          </Card>
        );
      })}
    </div>
  );
};

const TaxForm = ({ form, defaultValues, onChange, currency }) => {
  return (
    <div className="scrollBarStyle">
      <h3>Add a tax</h3>
      <WrappedTaxProvided form={form} defaultValues={defaultValues} onChange={onChange} currency={currency} />
    </div>
  );
};

const checkIsReasonRequired = (form, defaultValues) => {
  const formIsSuitableForChildren = form.getFieldValue('isSuitableForChildren');
  const formIsSuitableForInfant = form.getFieldValue('isSuitableForInfant');
  const isSuitableForChildren = formIsSuitableForChildren !== undefined ? formIsSuitableForChildren : defaultValues.isSuitableForChildren;
  const isSuitableForInfant = formIsSuitableForInfant !== undefined ? formIsSuitableForInfant : defaultValues.isSuitableForInfant;
  if (!isSuitableForChildren || !isSuitableForInfant) {
    return true;
  } else {
    return false;
  }
};

const checkMustClimbStairs = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasStairs');
  const isMustClimbStairs = formValue !== undefined ? formValue : defaultValues.houseRules.requires_stairs;
  if (isMustClimbStairs) {
    return true;
  } else {
    return false;
  }
};

const checkPotentialForNoise = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasNoise');
  const isPotentialForNoise = formValue !== undefined ? formValue : defaultValues.houseRules.potential_noise;
  if (isPotentialForNoise) {
    return true;
  } else {
    return false;
  }
};

const checkPetsLiveOnProperty = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasPets');
  const hasPets = formValue !== undefined ? formValue : defaultValues.houseRules.has_pets;
  if (hasPets) {
    return true;
  } else {
    return false;
  }
};

const checkNoParkingOnProperty = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasLimitedParking');
  const hasLimitedParking = formValue !== undefined ? formValue : defaultValues.houseRules.limited_parking;
  if (hasLimitedParking) {
    return true;
  } else {
    return false;
  }
};

const checkSharedSpaces = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasSharedSpaces');
  const hasSharedSpaces = formValue !== undefined ? formValue : defaultValues.houseRules.limited_amenities;
  if (hasSharedSpaces) {
    return true;
  } else {
    return false;
  }
};

const checkAmenityLimitations = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasLimitedAmenities');
  const hasLimitedAmenities = formValue !== undefined ? formValue : defaultValues.houseRules.limited_amenities;
  if (hasLimitedAmenities) {
    return true;
  } else {
    return false;
  }
};

const checkSurveillance = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasSurveillance');
  const hasSurveillance = formValue !== undefined ? formValue : defaultValues.houseRules.surveillance;
  if (hasSurveillance) {
    return true;
  } else {
    return false;
  }
};

const checkWeapons = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasWeapons');
  const hasWeapons = formValue !== undefined ? formValue : defaultValues.houseRules.weapons;
  if (hasWeapons) {
    return true;
  } else {
    return false;
  }
};

const checkDangerousAnimals = (form, defaultValues) => {
  const formValue = form.getFieldValue('hasAnimals');
  const hasAnimals = formValue !== undefined ? formValue : defaultValues.houseRules.animals;
  if (hasAnimals) {
    return true;
  } else {
    return false;
  }
};

const getCancellationPolicyValue = (form, defaultValues) => {
  return form.getFieldValue('cancellationPolicy') || defaultValues.cancellationPolicy;
};

const BookingSettingForm = ({ form, defaultValues }) => {
  const switchGeneralSelections = [{ key: true, value: true, displayValue: 'Yes' }, { key: false, value: false, displayValue: 'No' }];

  return (
    <div className="scrollBarStyle">
      <Row gutter={8}>
        <h3>House Rules</h3>
        <Col span={20}>
          <p className="toggleTitle">Suitable for Children (2-12 years)</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="isSuitableForChildren"
            defaultValue={!!defaultValues.isSuitableForChildren}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        <Col span={20}>
          <p className="toggleTitle">Suitable for Infants (0-2 years)</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="isSuitableForInfant"
            defaultValue={!!defaultValues.isSuitableForInfant}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkIsReasonRequired(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Reason why children or infants are not allowed to stay"
              name="reasonWhyNotAllowChild"
              defaultValue={defaultValues.reasonWhyNotAllowChild}
              extraRules={[
                {
                  required: true,
                  message: 'Please provide a reason why.'
                }
              ]}
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Pets Allowed</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="isPetsAllowed"
            defaultValue={!!defaultValues.isPetsAllowed}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Smoking Allowed</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="isSmokingAllowed"
            defaultValue={!!defaultValues.isSmokingAllowed}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Parties and Events Allowed</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="isPartiesAllowed"
            defaultValue={!!defaultValues.isPartiesAllowed}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Must Climb Stairs</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasStairs"
            defaultValue={!!defaultValues.houseRules.requires_stairs}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkMustClimbStairs(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe the stairs (for example, how many flights?)"
              name="stairsDetails"
              defaultValue={defaultValues.houseRules.requires_stairs}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Potential for Noise</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasNoise"
            defaultValue={!!defaultValues.houseRules.potential_noise}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkPotentialForNoise(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe the noise and when it's likely to take place"
              name="noiseDetails"
              defaultValue={defaultValues.houseRules.potential_noise}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Pet(s) Live on Property</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasPets"
            defaultValue={!!defaultValues.houseRules.has_pets}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkPetsLiveOnProperty(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe the pets you have"
              name="petsDetails"
              defaultValue={defaultValues.houseRules.has_pets}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">No Parking on Property</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasLimitedParking"
            defaultValue={!!defaultValues.houseRules.limited_parking}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkNoParkingOnProperty(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe the parking situation around your listing"
              name="limitedParkingDetails"
              defaultValue={defaultValues.houseRules.limited_parking}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Some Spaces are Shared</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasSharedSpaces"
            defaultValue={!!defaultValues.houseRules.shared_spaces}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkSharedSpaces(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe the spaces the guests will share"
              name="sharedSpacesDetails"
              defaultValue={defaultValues.houseRules.shared_spaces}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Amenity Limitations</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasLimitedAmenities"
            defaultValue={!!defaultValues.houseRules.limited_amenities}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkAmenityLimitations(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe an amenity or service that's limited, such as weak wifi or limited hot water"
              name="limitedAmenitiesDetails"
              defaultValue={defaultValues.houseRules.limited_amenities}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Surveillance or Recording Devices on Property</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasSurveillance"
            defaultValue={!!defaultValues.houseRules.surveillance}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkSurveillance(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe any device that records or sends video, audio, or still images. Specify each device's location and whether it will be on or off."
              name="surveillanceDetails"
              defaultValue={defaultValues.houseRules.surveillance}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Weapons on Property</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasWeapons"
            defaultValue={!!defaultValues.houseRules.weapons}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkWeapons(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe any weapons (firearms, airguns, tasers, etc.), their location, and how they're secured"
              name="weaponsDetails"
              defaultValue={defaultValues.houseRules.weapons}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={8}>
        <Col span={20}>
          <p className="toggleTitle">Dangerous Animals on Property</p>
        </Col>
        <Col span={4}>
          <FormRadioButton
            form={form}
            name="hasAnimals"
            defaultValue={!!defaultValues.houseRules.animals}
            buttonStyle="solid"
            selections={switchGeneralSelections}
          />
        </Col>
        {checkDangerousAnimals(form, defaultValues) && (
          <Col span={24} className="fullLengthTextBox">
            <FormInput
              form={form}
              placeholder="Describe any domesticated or wild animals that could be a health or safety risk to guests or other animals"
              name="animalsDetails"
              defaultValue={defaultValues.houseRules.animals}
              requiredErrorMessage="Please provide a description."
            />
          </Col>
        )}
        <Divider className="fullLengthTextBox" />
      </Row>
      <Row gutter={16}>
        <h3>Policies</h3>
        <Col span={12}>
          <FormTimePicker
            form={form}
            label="Check-in Start Time"
            fieldName="checkInStartTime"
            format="HH:00"
            disabledHours={[0, 1, 2, 3, 4, 5, 6, 7]}
            defaultValue={defaultValues.checkInStartTime}
            extraRules={[
              {
                validator: (rule, value, callback) => {
                  form.validateFields(['checkInEndTime'], { force: true });
                  callback();
                }
              }
            ]}
          />
        </Col>
        <Col span={12} className="halfLengthTextBox">
          <FormTimePicker
            form={form}
            label="Check-in End Time"
            fieldName="checkInEndTime"
            format="HH:00"
            disabledHours={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
            defaultValue={defaultValues.checkInEndTime}
            extraRules={[
              {
                validator: (rule, value, callback) => {
                  const startDate = getRealFieldValue(form, defaultValues, 'checkInStartTime');
                  if (value && !startDate) {
                    callback('Start time must be set');
                  } else if (!value) {
                    callback();
                  } else {
                    const diffTime = Number(value.format('H')) - Number(startDate.format('H'));
                    if (diffTime < 2) {
                      return callback(new Error('End time must be 2 hours ahead than start time'));
                    }
                    return callback();
                  }
                }
              }
            ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormTimePicker
            form={form}
            label="Check-out Time"
            fieldName="checkOutTime"
            format="HH:00"
            defaultValue={defaultValues.checkOutTime ? String(defaultValues.checkOutTime) : defaultValues.checkOutTime}
          />
        </Col>
        <Col span={12}>
          <FormRadioButton
            form={form}
            label="Cancellation Policy"
            name="cancellationPolicy"
            buttonStyle="solid"
            defaultValue={defaultValues.cancellationPolicy}
            selections={[
              { key: POLICY_FLEXIBLE_VALUE, value: POLICY_FLEXIBLE_VALUE, displayValue: 'Flexible' },
              { key: POLICY_MODERATE_VALUE, value: POLICY_MODERATE_VALUE, displayValue: 'Moderate' },
              { key: POLICY_STRICT_VALUE, value: POLICY_STRICT_VALUE, displayValue: 'Strict' }
            ]}
          />
        </Col>
        <Col span={24} className="fullLengthTextBox">
          {getCancellationPolicyValue(form, defaultValues) && (
            <Alert
              message="Tips"
              description={getCancellationPolicyDescription(getCancellationPolicyValue(form, defaultValues))}
              type="info"
              showIcon
              style={{ marginBottom: '16px' }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const defaultStandardFee = { amount: 0, amount_type: 'flat' };

class AirbnbAdvanceOptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toggleCustomValue: false,
      roomsOfRates: [],
      propertyTypeGroups: {},
      propertyTypes: {},
      roomTypes: {},
      calculatedMinExtraGuestFee: 24,
      airbnbCheckinOptions: {},
      airbnbCheckoutTasks: {},
      defaultDescription: {
        title: '',
        summary: '',
        space: '',
        access: '',
        interaction: '',
        notes: '',
        neighborhood: ''
      },
      defaultRoom: {
        numOfBathroom: 0,
        rooms: [],
        propertyTypeGroup: '',
        propertyTypeCategory: '',
        roomTypeCategory: ''
      },
      defaultAmenities: {
        amenities: []
      },
      defaultAddress: {
        street: '',
        city: '',
        zipcode: '',
        state: '',
        country: ''
      },
      defaultGuestResource: {
        checkinOption: '',
        checkinInstruction: ''
      },
      defaultPricing: {
        guestsIncluded: undefined,
        extraGuestFee: 0,
        cleaningFee: 0,
        weeklyDiscount: 0,
        monthlyDiscount: 0,
        // securityDeposit: 0,
        resortFee: defaultStandardFee,
        managementFee: defaultStandardFee,
        communityFee: defaultStandardFee,
        linenFee: defaultStandardFee,
        stdCleaningFee: defaultStandardFee,
        shortStayCleaningFee: defaultStandardFee,
        taxes: []
      },
      defaultAvailability: {
        advanceNoticeInHours: undefined,
        preparationTime: 0,
        minNight: 1,
        minNightMon: 1,
        minNightTue: 1,
        minNightWed: 1,
        minNightThu: 1,
        minNightFri: 1,
        minNightSat: 1,
        maxNightSun: 1,
        checkInDaysAllowed: [],
        checkOutDaysAllowed: []
      },
      defaultBookingSetting: {
        isSuitableForChildren: false,
        isSuitableForInfant: false,
        isPartiesAllowed: false,
        isPetsAllowed: false,
        isSmokingAllowed: false,
        reasonWhyNotAllowChild: '',
        checkInStartTime: undefined,
        checkInEndTime: undefined,
        checkOutTime: undefined,
        cancellationPolicy: undefined,
        houseRules: []
      },
      opportunities: [],
      opportunitiesToUpdate: [],
      reservationIssues: []
    };

    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleOnUpdate = this.handleOnUpdate.bind(this);
    this.handleToggleEnable = this.handleToggleEnable.bind(this);
  }

  async componentDidMount() {
    const { unitId, airbnbListingId, defaultValues, isFullSync, rateModifierTypeConstants, rateModifierSignConstants, currency } = this.props;

    this.setState({
      isLoading: true
    });
    this.constuctDefaultMinExtraGuestFee(currency);
    const [{ data }, propertyTypeGroups, propertyTypes, roomTypes, airbnbCheckinOptions, airbnbCheckoutTasks, roomsOfRates] = await Promise.all([
      getAirbnbAdvanceOptions(unitId, airbnbListingId),
      getConstantsNew('airbnbPropertyTypeGroups'),
      getConstantsNew('airbnbPropertyTypes'),
      getConstantsNew('airbnbRoomTypes'),
      getConstantsNew('airbnbCheckinOptions'),
      getConstantsNew('airbnbCheckoutTasks'),
      this.constructRoomsOfRates({ rateModifierTypeConstants, rateModifierSignConstants })
    ]);

    const mergedDefaultValues = this.mergeDefaultValuesWithData(data, defaultValues);
    let newStateObj = {
      isLoading: false,
      propertyTypeGroups,
      propertyTypes,
      roomTypes,
      airbnbCheckinOptions,
      airbnbCheckoutTasks: this.constructDefaultCheckoutTasks(mergedDefaultValues, airbnbCheckoutTasks),
      roomsOfRates,
      defaultPricing: this.constructDefaultPricing(mergedDefaultValues),
      defaultAvailability: this.constructDefaultAvailability(mergedDefaultValues),
      opportunities: mergedDefaultValues.opportunities || [],
      reservationIssues: mergedDefaultValues.listing.reservation_issues || []
    };
    if (isFullSync) {
      newStateObj.defaultRoom = this.constructDefaultRoom(mergedDefaultValues);
      newStateObj.defaultDescription = this.constructDefaultDescription(mergedDefaultValues);
      newStateObj.defaultAmenities = this.constructDefaultAmenities(mergedDefaultValues);
      newStateObj.defaultAddress = this.constructDefaultAddress(mergedDefaultValues);
      newStateObj.defaultGuestResource = this.constructDefaultGuestResource(mergedDefaultValues);
      newStateObj.defaultBookingSetting = this.constructDefaultBookingSetting(mergedDefaultValues);
    }
    if (newStateObj.defaultAvailability.advanceNoticeInHours < 24) {
      newStateObj.toggleCustomValue = true;
    }

    this.setState(newStateObj);
  }

  mergeDefaultValuesWithData(data, defaultValues) {
    if (!defaultValues || Object.keys(defaultValues).length === 0) {
      return data;
    }
    return Object.keys(defaultValues).reduce((mergedValues, key) => {
      const currentData = data[key];
      if (currentData) {
        const removedFalsyValueCurrentData = Object.keys(currentData).reduce((newCurrentValue, currentValueKey) => {
          if (currentData[currentValueKey] !== undefined) {
            return {
              ...newCurrentValue,
              [currentValueKey]: currentData[currentValueKey]
            };
          }
          return newCurrentValue;
        }, {});
        return {
          ...mergedValues,
          [key]: {
            ...removedFalsyValueCurrentData,
            ...defaultValues[key]
          }
        };
      } else {
        return {
          ...mergedValues,
          [key]: defaultValues[key]
        };
      }
    }, {});
  }

  constructDefaultDescription({ listing = {}, listing_description = {} }) {
    return {
      title: listing.name,
      summary: listing_description.summary,
      space: listing_description.space,
      access: listing_description.access,
      interaction: listing_description.interaction,
      notes: listing_description.notes,
      neighborhood: listing_description.neighborhood_overview
    };
  }

  constructDefaultRoom({ listing = {}, listing_rooms = [] }) {
    return {
      numOfBathroom: listing.bathrooms,
      rooms: listing_rooms,
      propertyTypeGroup: listing.property_type_group,
      propertyTypeCategory: listing.property_type_category,
      roomTypeCategory: listing.room_type_category
    };
  }

  constructDefaultAmenities({ listing = {} }) {
    return {
      amenities: listing.amenity_categories.map(categoryKey => {
        return {
          key: categoryKey,
          value: splitUnderscoreAndCapitalize(categoryKey)
        };
      })
    };
  }

  constructDefaultAddress({ listing = {} }) {
    return {
      street: listing.street,
      city: listing.city,
      zipcode: listing.zipcode,
      state: listing.state,
      country: listing.country_code
    };
  }

  constructDefaultGuestResource({ listing = {} }) {
    return {
      checkinOption: (listing.check_in_option && listing.check_in_option.category) || '',
      checkinInstruction: (listing.check_in_option && listing.check_in_option.instruction) || ''
    };
  }

  constructDefaultCheckoutTasks({ listing = {} }, tasks) {
    const deepMerge = (a, b, fn) =>
      [...new Set([...Object.keys(a), ...Object.keys(b)])].reduce((acc, key) => ({ ...acc, [key]: fn(key, a[key], b[key]) }), {});
    const concatFn = (key, a, b) => {
      if (Array.isArray(a) && Array.isArray(b)) return a.concat(b);
      if (typeof a === 'object' && typeof b === 'object') return deepMerge(a, b, concatFn);
      if (typeof a === 'string' && typeof b === 'string') return [a, b].join(' ');
      return b ? b : a;
    };

    //   {
    //     "RETURN_KEYS": {
    //         "code": "return_keys",
    //         "label": "Return keys to a specific place/person on checking out.",
    //         "is_present": true,
    //         "task_detail": " Our check out time is 12pm,  may check out *before 12pm* and return the key access card back to Faaz Mart and *take return key photo to us*"
    //     },
    //     "TURN_THINGS_OFF": {
    //         "code": "turn_things_off",
    //         "label": "Turn lights/appliances etc off on leaving.",
    //         "is_present": true,
    //         "task_detail": " Hi can turn off the house electric switch ( except Washing Machine , Refrigerator) "
    //     },
    //     "THROW_TRASH": {
    //         "code": "throw_trash",
    //         "label": "Take care of trash before leaving.",
    //         "is_present": true,
    //         "task_detail": " Kindly throw the rubbish to Rubbish Can that we provided in unit, thank you~"
    //     },

    return listing.check_out_tasks ? deepMerge(tasks, listing.check_out_tasks, concatFn) : tasks;
  }

  constructDefaultPricing({ listing, pricing_setting = {} }) {
    const standardFeesKeyValue = pricing_setting.standard_fees
      ? pricing_setting.standard_fees.reduce((newStandardFees, fee) => {
          return {
            ...newStandardFees,
            [fee.fee_type]: fee
          };
        }, {})
      : {};
    const getFeeObj = key => {
      const feeObj = standardFeesKeyValue[key];
      if (feeObj) {
        return {
          ...feeObj,
          amount: calcCorrectFee(feeObj.amount_type, feeObj.amount, true)
        };
      } else {
        return defaultStandardFee;
      }
    };

    return {
      maxCapacity: listing.person_capacity,
      guestsIncluded: pricing_setting.guests_included,
      extraGuestFee: pricing_setting.price_per_extra_person,
      // cleaningFee: pricing_setting.cleaning_fee,
      weeklyDiscount: calcCorrectDiscountFactor(pricing_setting.weekly_price_factor, true),
      monthlyDiscount: calcCorrectDiscountFactor(pricing_setting.monthly_price_factor, true),
      // securityDeposit: pricing_setting.security_deposit,
      resortFee: getFeeObj(AIRBNB_RESORT_FEE_KEY),
      managementFee: getFeeObj(AIRBNB_MANAGEMENT_FEE_KEY),
      communityFee: getFeeObj(AIRBNB_COMMUNITY_FEE_KEY),
      linenFee: getFeeObj(AIRBNB_LINEN_FEE_KEY),
      stdCleaningFee: getFeeObj(AIRBNB_CLEANING_FEE),
      shortStayCleaningFee: getFeeObj(AIRBNB_SHORT_STAY_CLEANING_FEE),
      taxes: pricing_setting.pass_through_taxes
        ? pricing_setting.pass_through_taxes.map((items, i) => {
            return {
              taxType: items.tax_type,
              amountType: items.amount_type,
              taxAmount: items.amount,
              businessTaxID: items.business_tax_id,
              accommodationsTaxRegistrationNumber: items.tot_registration_id,
              minimumDaysTaxExemption: items.long_term_stay_exemption,
              taxable_base: items.taxable_base,
              key: i++
            };
          })
        : []
    };
  }

  constuctTaxDetails = pricing_setting => {
    return pricing_setting.map((items, i) => {
      return {
        taxType: items.tax_type,
        amountType: items.amount_type,
        taxAmount: items.amount,
        businessTaxID: items.business_tax_id,
        accommodationsTaxRegistrationNumber: items.tot_registration_id,
        minimumDaysTaxExemption: items.long_term_stay_exemption,
        taxable_base: items.taxable_base,
        key: i++
      };
    });
  };

  constructDefaultAvailability({ availability_rule = {} }) {
    const flatDays = (daysOfWeek = []) => daysOfWeek.map(day => day.day_of_week);

    const defaultDOWMinNightPayload = {
      minNightSun: availability_rule.default_min_nights,
      minNightMon: availability_rule.default_min_nights,
      minNightTue: availability_rule.default_min_nights,
      minNightWed: availability_rule.default_min_nights,
      minNightThu: availability_rule.default_min_nights,
      minNightFri: availability_rule.default_min_nights,
      minNightSat: availability_rule.default_min_nights
    };
    const dowMinNightPayload = guard(
      () =>
        availability_rule.day_of_week_min_nights.reduce((result, dowMinNight) => {
          const dayOfWeek = guard(() => dowMinNight.day_of_week);
          const minNightOfTheDay = guard(() => dowMinNight.min_nights);

          if (dayOfWeek === 0) {
            result.minNightSun = minNightOfTheDay;
          } else if (dayOfWeek === 1) {
            result.minNightMon = minNightOfTheDay;
          } else if (dayOfWeek === 2) {
            result.minNightTue = minNightOfTheDay;
          } else if (dayOfWeek === 3) {
            result.minNightWed = minNightOfTheDay;
          } else if (dayOfWeek === 4) {
            result.minNightThu = minNightOfTheDay;
          } else if (dayOfWeek === 5) {
            result.minNightFri = minNightOfTheDay;
          } else if (dayOfWeek === 6) {
            result.minNightSat = minNightOfTheDay;
          }

          return result;
        }, defaultDOWMinNightPayload),
      defaultDOWMinNightPayload
    );

    return {
      advanceNoticeInHours: (availability_rule.booking_lead_time && availability_rule.booking_lead_time.hours) || 0,
      preparationTime: (availability_rule.turnover_days && availability_rule.turnover_days.days) || 0,
      minNight: availability_rule.default_min_nights,
      ...dowMinNightPayload,
      maxNight: availability_rule.default_max_nights,
      checkInDaysAllowed: flatDays(availability_rule.day_of_week_check_in),
      checkOutDaysAllowed: flatDays(availability_rule.day_of_week_check_out)
    };
  }

  constructDefaultBookingSetting({ booking_setting = {} }) {
    const getCheckinTime = timeString => {
      if (!timeString || timeString === 'NOT_SELECTED') {
        return undefined;
      }
      return timeString;
    };
    const defaultToTrueIfNull = value => {
      if (value === null) {
        return true;
      }
      return value;
    };
    const getHouseRules = bookings => {
      if (bookings) {
        const house = {};
        bookings.map(booking => (house[booking.type] = booking.added_details));
        return house;
      }
      return bookings;
    };

    return {
      isSuitableForChildren: booking_setting.guest_controls && defaultToTrueIfNull(booking_setting.guest_controls.allows_children_as_host),
      isSuitableForInfant: booking_setting.guest_controls && defaultToTrueIfNull(booking_setting.guest_controls.allows_infants_as_host),
      isPartiesAllowed: booking_setting.guest_controls && booking_setting.guest_controls.allows_events_as_host,
      isPetsAllowed: booking_setting.guest_controls && booking_setting.guest_controls.allows_pets_as_host,
      isSmokingAllowed: booking_setting.guest_controls && booking_setting.guest_controls.allows_smoking_as_host,
      reasonWhyNotAllowChild: (booking_setting.guest_controls && booking_setting.guest_controls.children_not_allowed_details) || '',
      cancellationPolicy: booking_setting.cancellation_policy_category,
      checkInStartTime: getCheckinTime(booking_setting.check_in_time_start),
      checkInEndTime: getCheckinTime(booking_setting.check_in_time_end),
      checkOutTime: booking_setting.check_out_time,
      houseRules: (booking_setting.listing_expectations_for_guests && getHouseRules(booking_setting.listing_expectations_for_guests)) || []
    };
  }

  constructRoomsOfRates = async ({ rateModifierTypeConstants, rateModifierSignConstants }) => {
    const { rate, roomType } = this.props;

    const webRates = await getWebRateByRoomType(roomType._id);
    const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);

    let calculation = rate.calculation;

    if (!calculation) {
      const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

      calculation = {
        amount: modifierTypeObject.isPercentage ? 0.01 : 1,
        type: modifierTypeObject.code,
        isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
      };
    }

    calculation = {
      ...calculation,
      parent: {
        weekday: stdRate.weekday,
        weekend: stdRate.weekend
      }
    };

    const rateObj = {
      weekday: rate.weekday,
      weekend: rate.weekend,
      isDerived: rate.isDerived,
      calculation
    };

    const roomsOfRates = [
      {
        rates: [
          {
            rate: rateObj
          }
        ]
      }
    ];

    return roomsOfRates;
  };

  constuctDefaultMinExtraGuestFee = async (currency = 'MYR') => {
    const fxRate = await getCurrencyExchangeRate();
    if (fxRate.status !== 200) {
      return;
    }

    const USDRate = fxRate.data.find(rate => rate.code === 'USD');
    const selectedRate = fxRate.data.find(rate => rate.code === currency);
    const AirbnbMinExtraGuestFee = 5; // 5 USD
    var calculatedMinExtraGuestFee = 0;

    if (!selectedRate) {
      return;
    }
    switch (currency) {
      case 'USD':
        calculatedMinExtraGuestFee = AirbnbMinExtraGuestFee;
        break;

      case 'MYR':
        calculatedMinExtraGuestFee = Math.ceil(AirbnbMinExtraGuestFee * USDRate.selling);
        break;

      default:
        // Min rate (USD) => MYR => IDR
        calculatedMinExtraGuestFee = Math.ceil(AirbnbMinExtraGuestFee * USDRate.selling * (selectedRate.unit / selectedRate.selling));
        break;
    }

    this.setState({
      calculatedMinExtraGuestFee
    });
  };

  handleOnUpdate = (withValueConstraint = false, opportunityType, inputKey, event, constraintType = 'NONE') => {
    const { form } = this.props;
    const { opportunitiesToUpdate } = this.state;
    form.validateFields((err, values) => {
      let payloadBody = {
        type: opportunityType
      };
      if (!err) {
        if (constraintType === 'OPTION') {
          payloadBody.inputKey = inputKey;
          payloadBody.value = event.target.value;
          const index = opportunitiesToUpdate.findIndex(item => item.type === opportunityType);
          if (index > -1) {
            opportunitiesToUpdate[index] = payloadBody;
          } else {
            this.setState({
              opportunitiesToUpdate: [...opportunitiesToUpdate, payloadBody]
            });
          }
        } else if (event === true && constraintType === 'NONE') {
          this.setState({
            opportunitiesToUpdate: [...opportunitiesToUpdate, payloadBody]
          });
        } else if (event !== '' && (constraintType === 'INTEGER' || constraintType === 'PERCENTAGE')) {
          if (withValueConstraint) {
            if (inputKey === 'losSettings.maxNights') {
              payloadBody.inputKey = inputKey;
              payloadBody.value = event;
            } else {
              payloadBody.inputKey = inputKey;
              payloadBody.value = event;
            }
          }
          const index = opportunitiesToUpdate.findIndex(item => item.inputKey === inputKey);
          if (index === -1) {
            this.setState({
              opportunitiesToUpdate: [...opportunitiesToUpdate, payloadBody]
            });
          } else {
            opportunitiesToUpdate[index] = payloadBody;
          }
        } else {
          const index = opportunitiesToUpdate.findIndex(item => item.type === opportunityType);
          if (index > -1) {
            opportunitiesToUpdate.splice(index, 1);
          }
          this.setState({
            opportunitiesToUpdate: opportunitiesToUpdate
          });
        }
      }
    });
  };

  handleToggleEnable = (e, task) => {
    const { airbnbCheckoutTasks } = this.state;

    this.setState({
      airbnbCheckoutTasks: {
        ...airbnbCheckoutTasks,
        [task]: {
          ...airbnbCheckoutTasks[task],
          is_present: e
        }
      }
    });
  };

  handleOnSave() {
    const { onConfirm, form, isFullSync } = this.props;
    const { opportunitiesToUpdate, airbnbCheckoutTasks } = this.state;
    const hasStairs = form.getFieldValue('hasStairs');
    const hasNoise = form.getFieldValue('hasNoise');
    const hasPets = form.getFieldValue('hasPets');
    const hasLimitedParking = form.getFieldValue('hasLimitedParking');
    const hasWeapons = form.getFieldValue('hasWeapons');
    const hasSharedSpaces = form.getFieldValue('hasSharedSpaces');
    const hasLimitedAmenities = form.getFieldValue('hasLimitedAmenities');
    const hasSurveillance = form.getFieldValue('hasSurveillance');
    const hasAnimals = form.getFieldValue('hasAnimals');

    form.validateFields((err, values) => {
      const constructStandardFees = formValues => {
        const standardFees = [];
        if (Number(formValues.resortFee) >= 0) {
          standardFees.push({
            fee_type: AIRBNB_RESORT_FEE_KEY,
            amount: calcCorrectFee(formValues.resortFeeType, formValues.resortFee),
            amount_type: formValues.resortFeeType
          });
        }

        if (Number(formValues.managementFee) >= 0) {
          standardFees.push({
            fee_type: AIRBNB_MANAGEMENT_FEE_KEY,
            amount: calcCorrectFee(formValues.managementFeeType, formValues.managementFee),
            amount_type: formValues.managementFeeType
          });
        }

        if (Number(formValues.communityFee) >= 0) {
          standardFees.push({
            fee_type: AIRBNB_COMMUNITY_FEE_KEY,
            amount: calcCorrectFee(formValues.communityFeeType, formValues.communityFee),
            amount_type: formValues.communityFeeType
          });
        }

        if (Number(formValues.linenFee) >= 0) {
          standardFees.push({
            fee_type: AIRBNB_LINEN_FEE_KEY,
            amount: calcCorrectFee(formValues.linenFeeType, formValues.linenFee),
            amount_type: formValues.linenFeeType
          });
        }

        if (Number(formValues.stdCleaningFee) >= 0) {
          standardFees.push({
            fee_type: AIRBNB_CLEANING_FEE,
            amount: calcCorrectFee(formValues.stdCleaningFeeType, formValues.stdCleaningFee),
            amount_type: formValues.stdCleaningFeeType
          });
        }

        if (Number(formValues.shortStayCleaningFee) > 0) {
          standardFees.push({
            fee_type: AIRBNB_SHORT_STAY_CLEANING_FEE,
            amount: calcCorrectFee(formValues.shortStayCleaningFeeType, formValues.shortStayCleaningFee),
            amount_type: formValues.shortStayCleaningFeeType
          });
        }
        return standardFees;
      };
      if (!err) {
        const fieldValues = form.getFieldsValue();
        const getCheckinTime = (startTime, endTime) => {
          if (startTime && endTime) {
            if (startTime.isAfter(endTime)) {
              return [endTime, startTime];
            } else {
              return [startTime, endTime];
            }
          }
          return [startTime, endTime];
        };
        const [checkInTimeStart, checkInTimeEnd] = getCheckinTime(values.checkInStartTime, values.checkInEndTime);
        const dayOfWeekMinNights = [
          { day_of_week: 0, min_nights: values.minNightSun },
          { day_of_week: 1, min_nights: values.minNightMon },
          { day_of_week: 2, min_nights: values.minNightTue },
          { day_of_week: 3, min_nights: values.minNightWed },
          { day_of_week: 4, min_nights: values.minNightThu },
          { day_of_week: 5, min_nights: values.minNightFri },
          { day_of_week: 6, min_nights: values.minNightSat }
        ];
        let taskObj = {};
        for (var key in airbnbCheckoutTasks) {
          if (airbnbCheckoutTasks[key].is_present === true) {
            taskObj = {
              ...taskObj,
              [key]: {
                is_present: true,
                task_detail: values[key]
              }
            };
          } else {
            taskObj = {
              ...taskObj,
              [key]: {
                is_present: false
              }
            };
          }
        }

        const payload = {
          listing: {},
          listing_description: {},
          booking_setting: {},
          opportunities: opportunitiesToUpdate,
          check_out_tasks: taskObj,
          pricing_setting: {
            guests_included: values.guestsIncluded,
            price_per_extra_person: values.extraGuestFee,
            // cleaning_fee: values.cleaningFee,
            weekly_price_factor: calcCorrectDiscountFactor(values.weeklyDiscount),
            monthly_price_factor: calcCorrectDiscountFactor(values.monthlyDiscount),
            // security_deposit: values.securityDeposit,
            standard_fees: constructStandardFees(values),
            pass_through_taxes:
              values.keys &&
              values.keys.map(key => {
                if (values.minimumDaysTaxExemption && values.minimumDaysTaxExemption[key]) {
                  return {
                    tax_type: values.taxType[key],
                    amount_type: values.amountType[key],
                    amount: values.taxAmount[key],
                    business_tax_id: values.businessTaxID[key],
                    tot_registration_id: values.accommodationsTaxRegistrationNumber[key],
                    attestation: true,
                    taxable_base: [
                      'base_price',
                      'pass_through_management_fee',
                      'pass_through_community_fee',
                      'pass_through_linen_fee',
                      'pass_through_resort_fee',
                      'pass_through_cleaning_fee',
                      'pass_through_pet_fee'
                    ],
                    long_term_stay_exemption: values.minimumDaysTaxExemption[key]
                  };
                } else {
                  return {
                    tax_type: values.taxType[key],
                    amount_type: values.amountType[key],
                    amount: values.taxAmount[key],
                    business_tax_id: values.businessTaxID[key],
                    tot_registration_id: values.accommodationsTaxRegistrationNumber[key],
                    attestation: true,
                    taxable_base: [
                      'base_price',
                      'pass_through_management_fee',
                      'pass_through_community_fee',
                      'pass_through_linen_fee',
                      'pass_through_resort_fee',
                      'pass_through_cleaning_fee',
                      'pass_through_pet_fee'
                    ]
                  };
                }
              })
          },
          availability_rule: {
            default_min_nights: values.minNight,
            day_of_week_min_nights: dayOfWeekMinNights,
            default_max_nights: values.maxNight,
            seasonal_min_nights: [
              {
                start_date: moment()
                  .subtract(1, 'day')
                  .format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
                min_nights: values.minNight || 1
              }
            ],
            booking_lead_time:
              (values.advanceNotice && Number(values.advanceNotice) > 0) ||
              (values.customAdvanceNotice &&
                24 - Number(values.customAdvanceNotice.format('H')) > 0 &&
                24 - Number(values.customAdvanceNotice.format('H')) < 24)
                ? values.customAdvanceNotice && Number(values.customAdvanceNotice.format('H')) === 0
                  ? { allow_request_to_book: 0, hours: values.customAdvanceNotice && Number(values.customAdvanceNotice.format('H')) }
                  : {
                      allow_request_to_book: 1,
                      hours:
                        values.customAdvanceNotice &&
                        24 - Number(values.customAdvanceNotice.format('H')) > 0 &&
                        24 - Number(values.customAdvanceNotice.format('H')) < 24
                          ? 24 - Number(values.customAdvanceNotice.format('H'))
                          : values.advanceNotice
                    }
                : (values.advanceNotice && Number(values.advanceNotice) === 0) ||
                  (values.customAdvanceNotice && Number(values.customAdvanceNotice.format('H')) === 0)
                ? { allow_request_to_book: 0, hours: values.advanceNotice }
                : undefined,
            turnover_days: Number(values.preparationTime) >= 0 ? { days: values.preparationTime } : undefined,
            day_of_week_check_in: values.checkInDaysAllowed ? values.checkInDaysAllowed.map(day => ({ day_of_week: day })) : undefined,
            day_of_week_check_out: values.checkOutDaysAllowed ? values.checkOutDaysAllowed.map(day => ({ day_of_week: day })) : undefined
          },
          rateDistributionPayload: getRateDistributionPayload(fieldValues)
        };
        if (isFullSync) {
          payload.listing = {
            name: values.title,
            property_type_group: values.propertyTypeGroup || undefined,
            property_type_category: values.propertyTypeCategory || undefined,
            room_type_category: values.roomTypeCategory || undefined,
            check_in_option: {
              category: values.checkinOption || undefined,
              instruction: values.checkinInstruction || undefined
            }
          };
          payload.booking_setting = {
            guest_controls: {
              allows_children_as_host: values.isSuitableForChildren,
              allows_infants_as_host: values.isSuitableForInfant,
              children_not_allowed_details: values.reasonWhyNotAllowChild,
              allows_pets_as_host: values.isPetsAllowed,
              allows_smoking_as_host: values.isSmokingAllowed,
              allows_events_as_host: values.isPartiesAllowed
            },
            check_in_time_start: checkInTimeStart ? checkInTimeStart.format('H') : undefined,
            check_in_time_end: checkInTimeEnd ? checkInTimeEnd.format('H') : undefined,
            check_out_time: values.checkOutTime ? values.checkOutTime.format('H') : undefined,
            cancellation_policy_category: values.cancellationPolicy,
            listing_expectations_for_guests: [
              hasStairs
                ? {
                    added_details: values.stairsDetails,
                    type: 'requires_stairs'
                  }
                : undefined,
              hasNoise
                ? {
                    added_details: values.noiseDetails,
                    type: 'potential_noise'
                  }
                : undefined,
              hasPets
                ? {
                    added_details: values.petsDetails,
                    type: 'has_pets'
                  }
                : undefined,
              hasLimitedParking
                ? {
                    added_details: values.limitedParkingDetails,
                    type: 'limited_parking'
                  }
                : undefined,
              hasSharedSpaces
                ? {
                    added_details: values.sharedSpacesDetails,
                    type: 'shared_spaces'
                  }
                : undefined,
              hasLimitedAmenities
                ? {
                    added_details: values.limitedAmenitiesDetails,
                    type: 'limited_amenities'
                  }
                : undefined,
              hasSurveillance
                ? {
                    added_details: values.surveillanceDetails,
                    type: 'surveillance'
                  }
                : undefined,
              hasWeapons
                ? {
                    added_details: values.weaponsDetails,
                    type: 'weapons'
                  }
                : undefined,
              hasAnimals
                ? {
                    added_details: values.animalsDetails,
                    type: 'animals'
                  }
                : undefined
            ].filter(k => k)
          };
        }
        console.log(2312, payload);

        onConfirm(payload);
      } else {
        let selectedTab = '';
        if (err.title || err.checkinInstruction) {
          selectedTab = 'details';
        } else if (
          err.reasonWhyNotAllowChild ||
          err.stairsDetails ||
          err.noiseDetails ||
          err.petsDetails ||
          err.limitedParkingDetails ||
          err.sharedSpacesDetails ||
          err.limitedAmenitiesDetails ||
          err.surveillanceDetails ||
          err.weaponsDetails ||
          err.animalsDetails ||
          err.checkInEndTime
        ) {
          selectedTab = 'bookingSetting';
        } else if (
          Object.keys(err).includes('Rate Distribution Tab----weekdayRate') ||
          Object.keys(err).includes('Rate Distribution Tab----weekendRate') ||
          Object.keys(err).includes('Rate Distribution Tab----modifierAmount')
        ) {
          selectedTab = 'rateDistribution';
        } else {
          selectedTab = 'tax';
        }
        const { history } = this.props;
        let query = queryString.parse(history.location.search);
        query = {
          ...query,
          selectedTab
        };
        history.push(`${history.location.pathname}?${queryString.stringify(query)}`);
      }
    });
  }

  setQueryToLink = activeTab => {
    const { history } = this.props;
    let query = queryString.parse(history.location.search);
    query = {
      ...query,
      selectedTab: activeTab
    };
    history.push({
      search: `?${queryString.stringify(query)}`
    });
  };

  handleOnClose = e => {
    const { history, onClose } = this.props;
    let query = queryString.parse(history.location.search);
    delete query.selectedTab;
    history.push({
      search: `?${queryString.stringify(query)}`
    });
    onClose(e);
  };

  toggleCustomValue = e => {
    if (e.target.value === 0) {
      return this.setState({
        toggleCustomValue: true
      });
    } else {
      return this.setState({
        toggleCustomValue: false
      });
    }
  };

  render() {
    const { isVisible, form, isFullSync, location, currency } = this.props;
    const {
      isLoading,
      defaultDescription,
      defaultRoom,
      defaultAmenities,
      defaultAddress,
      defaultGuestResource,
      defaultPricing,
      defaultAvailability,
      defaultBookingSetting,
      propertyTypeGroups,
      propertyTypes,
      roomTypes,
      roomsOfRates,
      airbnbCheckinOptions,
      airbnbCheckoutTasks,
      toggleCustomValue,
      opportunities,
      calculatedMinExtraGuestFee,
      reservationIssues
    } = this.state;
    const query = queryString.parse(location.search);
    const defaultActiveTabKey = isFullSync ? 'details' : 'pricing';
    return (
      <AdvancedOptionsModal isVisible={isVisible} isLoading={isLoading} onSave={this.handleOnSave} onClose={this.handleOnClose}>
        <Tabs
          defaultActiveKey={defaultActiveTabKey}
          onTabClick={this.setQueryToLink}
          activeKey={query.selectedTab ? query.selectedTab : defaultActiveTabKey}
        >
          {isFullSync && (
            <TabPane tab="Details" key="details">
              <DetailsForm
                form={form}
                defaultValues={{ ...defaultDescription, ...defaultRoom, ...defaultAmenities, ...defaultAddress, ...defaultGuestResource }}
                propertyTypeGroups={propertyTypeGroups}
                propertyTypes={propertyTypes}
                roomTypes={roomTypes}
                airbnbCheckinOptions={airbnbCheckinOptions}
              />
            </TabPane>
          )}
          {isFullSync && (
            <TabPane tab="Booking Setting" key="bookingSetting">
              <BookingSettingForm form={form} defaultValues={defaultBookingSetting} />
            </TabPane>
          )}
          <TabPane tab="Pricing" key="pricing" forceRender>
            <PricingForm form={form} defaultValues={defaultPricing} currency={currency} minExtraGuestFee={calculatedMinExtraGuestFee} />
          </TabPane>
          <TabPane tab="Availability" key="availability" forceRender>
            <AvailabilityForm form={form} defaultValues={defaultAvailability} toggle={this.toggleCustomValue} toggleCustomValue={toggleCustomValue} />
          </TabPane>
          <TabPane tab="Rate Distribution" key="rateDistribution">
            <AdvancedOptionsRateDistributionTab form={form} roomsOfRates={roomsOfRates} currency={currency} />
          </TabPane>
          {opportunities.length > 0 && (
            <TabPane tab="Opportunities" key="opportunities">
              <OpportunitiesTab form={form} defaultValues={opportunities} onUpdate={this.handleOnUpdate} />
            </TabPane>
          )}
          <TabPane tab="Check Out Tasks" key="checkoutTasks">
            <CheckoutTasksTab form={form} defaultValues={airbnbCheckoutTasks} onToggleEnable={this.handleToggleEnable} />
          </TabPane>
          {reservationIssues.length > 0 && (
            <TabPane tab="Reservation Issues" key="resIssues">
              <ResIssuesTab form={form} defaultValues={reservationIssues} />
            </TabPane>
          )}
          <TabPane tab="Tax" key="tax">
            <TaxForm form={form} defaultValues={defaultPricing.taxes} currency={currency} />
          </TabPane>
        </Tabs>
      </AdvancedOptionsModal>
    );
  }
}

AirbnbAdvanceOptionModal.propTypes = {
  airbnbListingId: PropTypes.string,
  defaultValues: PropTypes.object.isRequired,
  isFullSync: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  unitId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired
};

AirbnbAdvanceOptionModal.defaultProps = {
  isVisible: false,
  currency: 'RM',
  onConfirm: () => {},
  onClose: () => {}
};

export default withRouter(Form.create()(AirbnbAdvanceOptionModal));
